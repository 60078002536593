const IconAndValue = ({ IconName, text }) => {
  return (
    <p
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        marginBottom: 0,
      }}
    >
      <IconName
        style={{
          height: "30px",
          width: "30px",
        }}
      />
      {text}
    </p>
  );
};

export default IconAndValue;
