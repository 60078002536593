import axios from "axios";
// import * as XLSX from "xlsx";

const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "http://localhost:8123";

const getEmploi = async (_year, employmentType, territoryCode, territory) => {
  try {
    const response = await axios.post(`${BASE_URL}/emploi`, {
      year: _year,
      type_emploi: employmentType,
      code_territoire: territoryCode,
      territoire: territory,
    });
    const emplois = response.data.emplois;

    return emplois;
  } catch (error) {
    console.error("Error:", error);
    let errorMessage =
      "Une erreur est survenue lors du téléchargement des données.";

    // Personnalisation du message d'erreur en fonction du code HTTP
    if (error.response && error.response.status === 404) {
      errorMessage = "Les données demandées n'ont pas été trouvées.";
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }
    throw new Error(errorMessage); // Propager l'erreur avec le message personnalisé
  }
};

const getMultiplier = async (_year, territoryCode, territory) => {
  const multiplierTypes = [
    "emploi",
    "emploi_type_1",
    // "emploi_type_2",
    // "prod",
    // "prod_type_1",
    // "prod_type_2",
    "vab",
    "vab_type_1",
    // "vab_type_2",
  ];

  let results = {};
  try {
    const responses = await Promise.all(
      multiplierTypes.map((multiplierType) =>
        axios
          .post(`${BASE_URL}/mult`, {
            code_territoire: territoryCode,
            year: _year,
            multType: multiplierType,
            territoire: territory,
          })
          .then((response) => ({
            [multiplierType]: response.data.mults,
          }))
      )
    );

    results = responses.reduce((acc, current) => ({ ...acc, ...current }), {});
    const allEmpty = Object.values(results).every((arr) => arr.length === 0);
    if (allEmpty) {
      throw new Error("Les données demandées n'existent pas.");
    }
  } catch (error) {
    console.error("Error:", error);
    let errorMessage =
      "Une erreur est survenue lors du téléchargement des données.";
    if (error.message === "Les données demandées n'existent pas.") {
      throw error;
    } else if (error.response && error.response.status === 404) {
      errorMessage = "Les données demandées n'ont pas été trouvées.";
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }

    throw new Error(errorMessage);
  }
  return results;
};

export { getEmploi, getMultiplier };
