import { ContainerFullWidth } from "../../utils/style/Atoms";
import articlesResources from "../../data/articlesResources";
import ArticleResourceCard from "../../components/ArticleResourceCard";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

function Resources() {
  const CustomCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.neutral.main,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
    fontSize: "14px",
    borderRadius: "10px",
    color: theme.palette.text.primary,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }));

  return (
    <ContainerFullWidth>
      <h1>Ressources</h1>
      <p>
        Découvrez ici l’ensemble des ressources mises à disposition concernant
        l’outil ImpacTer.
      </p>
      <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
        {articlesResources.map((article) => (
          <Grid item key={article.id} xs={12} sm={6} md={6} lg={6} xl={6}>
            <CustomCard>
              <CardContent
                sx={{
                  flex: "1 1 auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ArticleResourceCard article={article} />
              </CardContent>
            </CustomCard>
          </Grid>
        ))}
      </Grid>
    </ContainerFullWidth>
  );
}

export default Resources;
