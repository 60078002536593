import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "http://localhost:8123";

const login = async (_username, _password) => {
  try {
    const response = await axios.post(`${BASE_URL}/user/login`, {
      username: _username,
      password: _password,
    });

    const token = response.data.token;
    localStorage.setItem("accessToken", token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } catch (error) {
    console.error("Error:", error);
    let errorMessage = "Une erreur est survenue lors de la connexion.";
    if (error.response && error.response.status === 401) {
      errorMessage =
        "Les informations d'identification fournies sont incorrectes. Veuillez vérifier votre nom d'utilisateur et votre mot de passe et réessayer.";
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }
    throw new Error(errorMessage);
  }
};

const logout = () => {
  localStorage.removeItem("accessToken");
  delete axios.defaults.headers.common["Authorization"];
};

const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export { login, logout, getAccessToken };
