import { useState } from "react";
import ClassicForm from "../../components/ClassicForm";
import { getProfileForYearAndSiren } from "../../services/Classique/ClassiqueService";
import {
  formatNumberWithComaAndSpace,
  formatValue,
  getMaxUnit,
} from "../../services/Finance/FinanceHelpers";
import BasicTable from "../../components/BasicTable";

function ClassicResultsTab() {
  const maxYear = parseInt(new Date().getFullYear() - 2);
  const [formValid, setFormValid] = useState(true);
  const [year, setYear] = useState(maxYear);
  const [sirenGroup, setSirenGroup] = useState("");
  const [territory, setTerritory] = useState("France");
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileData, setProfileData] = useState([]);
  const [rowsImpact, setRowsImpact] = useState([]);
  const [headerImpact, setHeaderImpact] = useState([]);

  const [rowsMult, setRowsMult] = useState([]);
  const [headerMult, setHeaderMult] = useState([]);

  const createFormattedData = (profile, maxAllUnits) => {
    const formattedData = {
      formattedDirectProd: formatValue(profile.production, maxAllUnits),
      formattedIndirectProd: formatValue(
        profile.indirect_production,
        maxAllUnits
      ),
      formattedInduiteRemunProd: formatValue(
        profile.induit_production_remuneration,
        maxAllUnits
      ),
      formattedInduiteFournProd: formatValue(
        profile.induit_production_fournisseur,
        maxAllUnits
      ),
      formattedInduiteTotProd: formatValue(
        parseFloat(profile.induit_production_fournisseur) +
          parseFloat(profile.induit_production_remuneration),
        maxAllUnits
      ),
      formattedTotProd: formatValue(
        parseFloat(profile.induit_production_fournisseur) +
          parseFloat(profile.induit_production_remuneration) +
          parseFloat(profile.indirect_production) +
          parseFloat(profile.production),
        maxAllUnits
      ),
      formattedDirectVAB: formatValue(profile.VAB, maxAllUnits),
      formattedIndirectVAB: formatValue(profile.indirect_VAB, maxAllUnits),
      formattedInduiteRemunVAB: formatValue(
        profile.induit_VAB_remuneration,
        maxAllUnits
      ),
      formattedInduiteFournVAB: formatValue(
        profile.induit_VAB_fournisseur,
        maxAllUnits
      ),
      formattedInduiteTotVAB: formatValue(
        parseFloat(profile.induit_VAB_fournisseur) +
          parseFloat(profile.induit_VAB_remuneration),
        maxAllUnits
      ),
      formattedTotVAB: formatValue(
        parseFloat(profile.induit_VAB_fournisseur) +
          parseFloat(profile.induit_VAB_remuneration) +
          parseFloat(profile.indirect_VAB) +
          parseFloat(profile.VAB),
        maxAllUnits
      ),
    };

    return formattedData;
  };

  const createRowsImpact = (profile, formattedData) => {
    return [
      {
        impact: "Direct",
        prod: formatNumberWithComaAndSpace(formattedData.formattedDirectProd),
        vab: formatNumberWithComaAndSpace(formattedData.formattedDirectVAB),
        etp: formatNumberWithComaAndSpace(profile.effectif),
      },
      {
        impact: "Indirect",
        prod: formatNumberWithComaAndSpace(formattedData.formattedIndirectProd),
        vab: formatNumberWithComaAndSpace(formattedData.formattedIndirectVAB),
        etp: formatNumberWithComaAndSpace(
          parseFloat(profile.indirect_emploi).toFixed(0)
        ),
      },
      {
        impact: "Induit (rémunération)",
        prod: formatNumberWithComaAndSpace(
          formattedData.formattedInduiteRemunProd
        ),
        vab: formatNumberWithComaAndSpace(
          formattedData.formattedInduiteRemunVAB
        ),
        etp: formatNumberWithComaAndSpace(
          parseFloat(profile.induit_emploi_remuneration).toFixed(0)
        ),
      },
      {
        impact: "Induit (achats)",
        prod: formatNumberWithComaAndSpace(
          formattedData.formattedInduiteFournProd
        ),
        vab: formatNumberWithComaAndSpace(
          formattedData.formattedInduiteFournVAB
        ),
        etp: formatNumberWithComaAndSpace(
          parseFloat(profile.induit_emploi_fournisseur).toFixed(0)
        ),
      },
      {
        impact: "Induit (total)",
        prod: formatNumberWithComaAndSpace(
          formattedData.formattedInduiteTotProd
        ),
        vab: formatNumberWithComaAndSpace(formattedData.formattedInduiteTotVAB),
        etp: formatNumberWithComaAndSpace(
          (
            parseFloat(profile.induit_emploi_fournisseur) +
            parseFloat(profile.induit_emploi_remuneration)
          ).toFixed(0)
        ),
      },
      {
        impact: "TOTAL",
        prod: formatNumberWithComaAndSpace(formattedData.formattedTotProd),
        vab: formatNumberWithComaAndSpace(formattedData.formattedTotVAB),
        etp: formatNumberWithComaAndSpace(
          (
            parseFloat(profile.induit_emploi_fournisseur) +
            parseFloat(profile.induit_emploi_remuneration) +
            parseFloat(profile.indirect_emploi) +
            parseFloat(profile.effectif)
          ).toFixed(0)
        ),
      },
    ];
  };

  const createRowsMult = (profile) => {
    return [
      {
        impact: "Direct",
        prod: formatNumberWithComaAndSpace(
          profile.production / profile.production
        ),
        vab: formatNumberWithComaAndSpace(
          (profile.VAB / profile.production).toFixed(2)
        ),
        etp: formatNumberWithComaAndSpace(
          ((profile.effectif * 1e6) / profile.production).toFixed(2)
        ),
      },
      {
        impact: "Indirect",
        prod: formatNumberWithComaAndSpace(
          (profile.indirect_production / profile.production).toFixed(2)
        ),
        vab: formatNumberWithComaAndSpace(
          (profile.indirect_VAB / profile.production).toFixed(2)
        ),
        etp: formatNumberWithComaAndSpace(
          ((profile.indirect_emploi * 1e6) / profile.production).toFixed(2)
        ),
      },
      {
        impact: "Induit (rémunération)",
        prod: formatNumberWithComaAndSpace(
          (profile.induit_production_remuneration / profile.production).toFixed(
            2
          )
        ),
        vab: formatNumberWithComaAndSpace(
          (profile.induit_VAB_remuneration / profile.production).toFixed(2)
        ),
        etp: formatNumberWithComaAndSpace(
          (
            (profile.induit_emploi_remuneration * 1e6) /
            profile.production
          ).toFixed(2)
        ),
      },
      {
        impact: "Induit (achats)",
        prod: formatNumberWithComaAndSpace(
          (profile.induit_production_fournisseur / profile.production).toFixed(
            2
          )
        ),
        vab: formatNumberWithComaAndSpace(
          (profile.induit_VAB_fournisseur / profile.production).toFixed(2)
        ),
        etp: formatNumberWithComaAndSpace(
          (
            (profile.induit_emploi_fournisseur * 1e6) /
            profile.production
          ).toFixed(2)
        ),
      },
      {
        impact: "Induit (total)",
        prod: formatNumberWithComaAndSpace(
          (
            (parseFloat(profile.induit_production_remuneration) +
              parseFloat(profile.induit_production_fournisseur)) /
            profile.production
          ).toFixed(2)
        ),
        vab: formatNumberWithComaAndSpace(
          (
            (parseFloat(profile.induit_VAB_remuneration) +
              parseFloat(profile.induit_VAB_fournisseur)) /
            profile.production
          ).toFixed(2)
        ),
        etp: formatNumberWithComaAndSpace(
          (
            (profile.induit_emploi_fournisseur * 1e6 +
              parseFloat(profile.induit_emploi_remuneration)) /
            profile.production
          ).toFixed(2)
        ),
      },
      {
        impact: "TOTAL",
        prod: formatNumberWithComaAndSpace(
          (
            (parseFloat(profile.induit_production_remuneration) +
              parseFloat(profile.induit_production_fournisseur) +
              parseFloat(profile.indirect_production) +
              parseFloat(profile.production)) /
            profile.production
          ).toFixed(2)
        ),
        vab: formatNumberWithComaAndSpace(
          (
            (parseFloat(profile.induit_VAB_fournisseur) +
              parseFloat(profile.induit_VAB_remuneration) +
              parseFloat(profile.indirect_VAB) +
              parseFloat(profile.VAB)) /
            profile.production
          ).toFixed(2)
        ),
        etp: formatNumberWithComaAndSpace(
          (
            (profile.induit_emploi_fournisseur * 1e6 +
              profile.induit_emploi_remuneration * 1e6 +
              profile.indirect_emploi * 1e6 +
              parseFloat(profile.effectif) * 1e6) /
            profile.production
          ).toFixed(2)
        ),
      },
    ];
  };

  async function handleResearch() {
    if (year && sirenGroup && territory) {
      try {
        setErrorAlert(false);
        setProfileData({});
        setLoading(true);
        const profile = await getProfileForYearAndSiren(year, sirenGroup);
        if (profile.indirect_production) {
          setProfileData(profile);

          const maxAllUnits = getMaxUnit([
            profile.VAB,
            profile.production,
            profile.indirect_production,
            profile.induit_production_remuneration,
            profile.induit_production_fournisseur,
            profile.indirect_VAB,
            profile.induit_VAB_remuneration,
            profile.induit_VAB_fournisseur,
          ]);

          const formattedData = createFormattedData(profile, maxAllUnits);

          setRowsImpact(createRowsImpact(profile, formattedData));
          setHeaderImpact([
            "",
            `Production (${maxAllUnits})`,
            `Valeur ajoutée (${maxAllUnits})`,
            "Emploi (ETP)",
          ]);

          setRowsMult(createRowsMult(profile));
          setHeaderMult([
            "",
            `Production`,
            `Valeur ajoutée`,
            "Emploi (ETP/M€)",
          ]);
        } else {
          throw new Error(
            `Il n'y a pas encore de résultats pour le groupe ${sirenGroup} et l'année ${year}. Avez-vous bien lancé le calcul d'impacts dans le sous-onglet "Complétion des infos" ?`
          );
        }
      } catch (err) {
        setErrorAlert(true);
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      setFormValid(false);
    }
  }

  return (
    <>
      <h1>Étape 3 : Résultats</h1>
      <ClassicForm
        year={year}
        setYear={setYear}
        maxYear={maxYear}
        sirenGroup={sirenGroup}
        setSirenGroup={setSirenGroup}
        territory={territory}
        setTerritory={setTerritory}
        formValid={formValid}
        handleResearch={handleResearch}
        loading={loading}
        errorAlert={errorAlert}
        errorMessage={errorMessage}
        setErrorAlert={setErrorAlert}
      />
      {Object.keys(profileData).length !== 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <h2>
            Impacts du groupe {profileData.nom_entite} en {territory}
          </h2>
          <p>SIREN : {profileData.siren}</p>
          <BasicTable rows={rowsImpact} header={headerImpact} />
          <h3>Multiplicateurs</h3>
          <BasicTable rows={rowsMult} header={headerMult} />
        </div>
      )}
    </>
  );
}

export default ClassicResultsTab;
