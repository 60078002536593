import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ArticleResourceCard = ({ article }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ marginBottom: "auto" }}>
        <h2 style={{ display: "flex", alignItems: "center" }}>
          <HelpOutlineOutlinedIcon color="secondary" />
          <span style={{ marginLeft: "10px" }}>{article.title}</span>
        </h2>
        <div dangerouslySetInnerHTML={{ __html: article.summary }} />
      </div>
      <div style={{ marginTop: "auto", textAlign: "right" }}>
        <Button component={Link} to={`/resources/${article.id}`}>
          En savoir plus
        </Button>
      </div>
    </div>
  );
};

export default ArticleResourceCard;
