import { useState, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";

function FileUploader({ onDataUpload, error, numberOfSheets }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputKey, setInputKey] = useState(0);
  const [isGoodFormat, setIsGoodFormat] = useState(true);
  const [isGoodSize, setIsGoodSize] = useState(true);
  const [moreThanTwoLines, setMoreThanTwoLines] = useState(true);
  const [isFileOK, setIsFileOK] = useState(false);
  const [internalError, setInternalError] = useState(false);

  useEffect(() => {
    setInternalError(error);
  }, [error]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file || !file.name.endsWith(".xlsx")) {
      setIsGoodFormat(false);
      setIsFileOK(false);
    } else {
      setIsGoodFormat(true);
      setSelectedFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    if (!file || !file.name.endsWith(".xlsx")) {
      setIsGoodFormat(false);
      setIsFileOK(false);
    } else {
      setIsGoodFormat(true);
      setSelectedFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDelete = () => {
    setSelectedFile(null);
    setIsFileOK(false);
    setInputKey((prevKey) => prevKey + 1);
    onDataUpload(null);
  };

  useEffect(() => {
    const handleUpload = () => {
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNames = workbook.SheetNames;
          const allSheetsData = [];

          sheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            let sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            sheetData = sheetData.filter((row) =>
              row.some((cell) => cell !== null && cell !== "")
            );

            allSheetsData.push(sheetData);
          });

          const isValid = allSheetsData.every(
            (sheetData) => sheetData.length >= 2
          );

          const isEnoughSheets = allSheetsData.length === numberOfSheets;

          if (isValid && isEnoughSheets) {
            setIsFileOK(true);
            setIsGoodSize(true);

            onDataUpload(allSheetsData);
            setInternalError(false);
          } else if (!isEnoughSheets) {
            setIsGoodSize(false);
            setIsFileOK(false);
            onDataUpload(null);
          } else {
            setMoreThanTwoLines(false);
            setIsFileOK(false);
            onDataUpload(null);
          }
        };
        reader.readAsArrayBuffer(selectedFile);
      }
    };
    handleUpload();
  }, [selectedFile, onDataUpload, numberOfSheets]);

  return (
    <div style={{ height: "100%", maxWidth: "100%" }}>
      {!isFileOK && (
        <InputLabel htmlFor={`file-input-${inputKey}`}>
          <Paper
            data-testid="file-uploader"
            sx={{
              textAlign: "center",
              padding: "20px",
              border: "1.5px dashed",
              borderColor:
                isGoodFormat && moreThanTwoLines && !internalError && isGoodSize
                  ? "grey.main"
                  : "danger.main",
              borderRadius: "20px",
              cursor: "pointer",
              backgroundColor: "background.main",
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            elevation={0}
          >
            <Input
              key={inputKey}
              id={`file-input-${inputKey}`}
              type="file"
              sx={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              startIcon={<CloudUploadIcon />}
              variant="contained"
              component="span"
            >
              Choisir un fichier
            </Button>

            <p>ou</p>
            <p>Glisser et déposer un fichier ici</p>
          </Paper>
        </InputLabel>
      )}
      {!isFileOK && (!isGoodFormat || internalError) && (
        <FormHelperText sx={{ color: "danger.main" }}>
          Veuillez sélectionner un fichier .xlsx
        </FormHelperText>
      )}
      {!moreThanTwoLines && !isFileOK && (
        <FormHelperText sx={{ color: "danger.main" }}>
          Veuillez sélectionner un fichier contenant plus de deux lignes
        </FormHelperText>
      )}
      {!isGoodSize && !isFileOK && (
        <FormHelperText sx={{ color: "danger.main" }}>
          Le fichier excel doit contenir {numberOfSheets} feuille(s).
        </FormHelperText>
      )}
      {isFileOK && (
        <div>
          <p>
            Fichier sélectionné : {selectedFile.name}
            {selectedFile && (
              <IconButton
                data-testid="delete-btn"
                onClick={handleDelete}
                color="danger"
              >
                <DeleteForeverIcon />
              </IconButton>
            )}
          </p>
        </div>
      )}
    </div>
  );
}

FileUploader.propTypes = {
  onDataUpload: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  numberOfSheets: PropTypes.number,
};

FileUploader.defaultProps = {
  numberOfSheets: 1,
};

export default FileUploader;
