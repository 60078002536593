import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import LoginPage from "../pages/LoginPage";
import Home from "../pages/Home";
import Finance from "../pages/Finance";
import Tools from "../pages/Tools";
import Error from "../components/Error";
import Header from "../containers/Header";
// import Footer from "../components/Footer";
import LogoutPage from "../pages/LogoutPage";
import Resources from "../pages/Resources";
import ArticleResourceDetail from "../pages/ArticleResourceDetails";
import Classic from "../pages/Classic";

const Layout = ({ children }) => {
  const { token } = useAuth();

  return (
    <div className="container">
      {token && <Header />}

      <div className="content">{children}</div>

      {/* {token && <Footer />} */}
    </div>
  );
};

const RoutesApp = () => {
  const { token } = useAuth();

  return (
    <Layout>
      <Routes>
        {/* Redirection vers la page de connexion si l'utilisateur n'est pas authentifié */}
        {!token && <Route path="/*" element={<Navigate to="/login" />} />}
        {!token && <Route path="/login" element={<LoginPage />} />}

        {/* Routes protégées nécessitant une authentification */}
        {token && (
          <>
            <Route
              path="/"
              element={<ProtectedRoute />}
              children={
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/finance" element={<Finance />} />
                  <Route path="/classique" element={<Classic />} />
                  <Route path="/tools" element={<Tools />} />
                  <Route path="/resources" element={<Resources />} />
                  <Route
                    path="/resources/:id"
                    element={<ArticleResourceDetail />}
                  />
                  <Route path="/logout" element={<LogoutPage />} />
                  <Route path="/login" element={<Home />} />
                </>
              }
            />
            <Route path="/*" element={<Error />} />
          </>
        )}
      </Routes>
    </Layout>
  );
};

export default RoutesApp;
