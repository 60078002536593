import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../utils/context";

import Logo from "../../components/Logo";
import SwitchTheme from "../../components/SwitchTheme";
import HeaderNavigationTabs from "../../components/HeaderNavigationTabs";
import MenuDrawer from "../../components/MenuDrawer";
import LogoutDialog from "../../components/LogoutDialog";
import IconMenuDrawer from "../../components/IconMenuDrawer";
import { useAuth } from "../../provider/authProvider";

function Header() {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    signOut();
    navigate("/login", { replace: true });
  };

  const handleConfirmLogout = () => {
    handleLogout();
    setConfirmLogoutOpen(false);
  };

  return (
    <>
      <AppBar position="static" color="neutral" enableColorOnDark>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "auto" }}>
            <Logo theme={theme} />
          </div>
          <MenuDrawer
            toggleDrawer={toggleDrawer}
            drawerOpen={drawerOpen}
            setConfirmLogoutOpen={setConfirmLogoutOpen}
          />
          <IconMenuDrawer toggleDrawer={toggleDrawer} />
          <HeaderNavigationTabs location={location} theme={theme} />
          <SwitchTheme />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setConfirmLogoutOpen(true);
            }}
            style={{
              marginLeft: "25px",
              height: "40px",
            }}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            Déconnexion
          </Button>
        </Toolbar>
      </AppBar>
      <LogoutDialog
        confirmLogoutOpen={confirmLogoutOpen}
        setConfirmLogoutOpen={setConfirmLogoutOpen}
        handleConfirmLogout={handleConfirmLogout}
        theme={theme}
      />
    </>
  );
}

export default Header;
