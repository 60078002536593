import NumberInput from "../../components/NumberInput";
import BasicTable from "../../components/BasicTable";
import MultipliersTooltip from "../../components/MultipliersTooltip";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getProfileGroupBySiren,
  getAllSirenGroupForYear,
} from "../../services/Finance/FinanceService";
import {
  getMaxUnit,
  formatNumberWithComaAndSpace,
  formatValue,
} from "../../services/Finance/FinanceHelpers";
import ImpactSchema from "../../components/ImpactSchema";
import GroupImpactData from "../GroupImpactData";
import React, { useState } from "react";
import { ErrorAlert } from "../../components/Alert";
import SearchGroupAutocomplete from "../../components/SearchGroupAutocomplete";

function ImpactByGroupTab() {
  const maxYear = parseInt(new Date().getFullYear() - 1);
  const [sirenGroup, setSirenGroup] = useState("");
  const [displayedSirenGroup, setDisplayedSirenGroup] = useState("");
  const [year, setYear] = useState(maxYear);
  const [profileGroup, setProfileGroup] = useState({});
  const [formValid, setFormValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rowsMult, setRowsMult] = useState([]);
  const [rowsImpact, setRowsImpact] = useState([]);
  const [headerImpact, setHeaderImpact] = useState([]);
  const [maxUnit, setMaxUnit] = useState("");

  async function fetchSirenOptions(year, query) {
    return await getAllSirenGroupForYear(year, query, "profil-groupe");
  }

  function handleSirenGroupChange(siren, displayedValue) {
    setSirenGroup(siren);
    setDisplayedSirenGroup(displayedValue);
  }

  async function handleResearch() {
    if (year && sirenGroup) {
      try {
        setProfileGroup({});
        setMaxUnit("");
        setLoading(true);
        const profile = await getProfileGroupBySiren(year, sirenGroup);
        if (profile.direct_production !== 0) {
          setProfileGroup(profile);

          const maxAllUnits = getMaxUnit([
            profile.direct_production,
            profile.type_I_production,
            profile.type_II_production,
            profile.direct_VAB,
            profile.type_I_VAB,
            profile.type_II_VAB,
          ]);
          setMaxUnit(maxAllUnits);

          const formattedDirectProd = formatValue(
            profile.direct_production,
            maxAllUnits
          );
          const formattedTypeIProd = formatValue(
            profile.type_I_production,
            maxAllUnits
          );
          const formattedTypeIIProd = formatValue(
            profile.type_II_production,
            maxAllUnits
          );

          const formattedDirectVAB = formatValue(
            profile.direct_VAB,
            maxAllUnits
          );
          const formattedTypeIVAB = formatValue(
            profile.type_I_VAB,
            maxAllUnits
          );
          const formattedTypeIIVAB = formatValue(
            profile.type_II_VAB,
            maxAllUnits
          );
          setRowsImpact([
            {
              impact: "Direct",
              prod: formatNumberWithComaAndSpace(formattedDirectProd),
              vab: formatNumberWithComaAndSpace(formattedDirectVAB),
              etp: formatNumberWithComaAndSpace(
                profile.direct_emploi.toFixed(0)
              ),
            },
            {
              impact: "Type I",
              prod: formatNumberWithComaAndSpace(formattedTypeIProd),
              vab: formatNumberWithComaAndSpace(formattedTypeIVAB),
              etp: formatNumberWithComaAndSpace(
                profile.type_I_emploi.toFixed(0)
              ),
            },
            {
              impact: "Type II",
              prod: formatNumberWithComaAndSpace(formattedTypeIIProd),
              vab: formatNumberWithComaAndSpace(formattedTypeIIVAB),
              etp: formatNumberWithComaAndSpace(
                profile.type_II_emploi.toFixed(0)
              ),
            },
          ]);
          setRowsMult([
            {
              impact: "Direct",
              prod: formatNumberWithComaAndSpace(
                (profile.direct_production / profile.direct_production).toFixed(
                  2
                )
              ),
              vab: formatNumberWithComaAndSpace(
                (profile.direct_VAB / profile.direct_production).toFixed(2)
              ),
              etp: formatNumberWithComaAndSpace(
                (
                  (profile.direct_emploi * 1000000) /
                  profile.direct_production
                ).toFixed(1)
              ),
            },
            {
              impact: "Type I",
              prod: formatNumberWithComaAndSpace(
                (profile.type_I_production / profile.direct_production).toFixed(
                  2
                )
              ),
              vab: formatNumberWithComaAndSpace(
                (profile.type_I_VAB / profile.direct_production).toFixed(2)
              ),
              etp: formatNumberWithComaAndSpace(
                (
                  (profile.type_I_emploi * 1000000) /
                  profile.direct_production
                ).toFixed(1)
              ),
            },
            {
              impact: "Type II",
              prod: formatNumberWithComaAndSpace(
                (
                  profile.type_II_production / profile.direct_production
                ).toFixed(2)
              ),
              vab: formatNumberWithComaAndSpace(
                (profile.type_II_VAB / profile.direct_production).toFixed(2)
              ),
              etp: formatNumberWithComaAndSpace(
                (
                  (profile.type_II_emploi * 1000000) /
                  profile.direct_production
                ).toFixed(1)
              ),
            },
          ]);
          setHeaderImpact([
            "",
            `Production (${maxAllUnits})`,
            `Valeur ajoutée (${maxAllUnits})`,
            "Emploi (ETP)",
          ]);
          setErrorAlert(false);
        } else {
          throw new Error(
            "Il semblerait que les données du groupe recherché n'aient pas été correctement calculées. Veuillez repasser par les étapes précédentes."
          );
        }
      } catch (error) {
        setErrorAlert(true);
        setProfileGroup({});
        setRowsMult([]);
        setRowsImpact([]);
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setFormValid(false);
    }
  }
  const headerMult = ["", "Production", "Valeur ajoutée", "Emploi (ETP/M€)"];

  const formattedErrorMessage = errorMessage.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div>
      <h1>Retombées par groupe</h1>
      <FormGroup
        sx={{
          rowGap: "2rem",
          width: "90%",
        }}
      >
        <NumberInput
          label="Année d'étude"
          value={year}
          onChange={(year) => setYear(year)}
          minValue={2015}
          maxValue={maxYear}
        />
        <SearchGroupAutocomplete
          year={year}
          value={displayedSirenGroup}
          onChange={handleSirenGroupChange}
          fetchOptions={fetchSirenOptions}
          label="Choisissez un groupe :"
          formValid={formValid}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            data-testid="btn-research"
            variant="contained"
            sx={{
              width: "15%",
              minWidth: "110px",
              marginRight: "15px",
            }}
            onClick={handleResearch}
          >
            Rechercher
          </Button>
          {loading && <CircularProgress size={25} />}
        </div>
        {errorAlert && (
          <ErrorAlert
            message={formattedErrorMessage}
            onClose={() => setErrorAlert(false)}
          />
        )}
      </FormGroup>

      {Object.keys(profileGroup).length !== 0 && (
        <>
          <h2>Retombées du groupe {profileGroup.denomination_groupe}</h2>
          <p> SIREN : {profileGroup.siren_groupe}</p>
          <GroupImpactData profileGroup={profileGroup} unit={maxUnit} />
          <h3>Impacts</h3>
          <BasicTable rows={rowsImpact} header={headerImpact} />
          <ImpactSchema profile={profileGroup} unit={maxUnit} />

          <h3>
            Multiplicateurs
            <MultipliersTooltip />
          </h3>
          <BasicTable rows={rowsMult} header={headerMult} />
        </>
      )}
    </div>
  );
}

export default ImpactByGroupTab;
