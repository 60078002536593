import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const DrawerList = ({ toggleDrawer }) => {
  return (
    <List>
      <ListItemButton component={Link} to="/" onClick={toggleDrawer(false)}>
        <ListItemText primary="Accueil" />
      </ListItemButton>
      <ListItemButton
        component={Link}
        to="/classique"
        onClick={toggleDrawer(false)}
      >
        <ListItemText primary="Classique" />
      </ListItemButton>
      <ListItemButton
        component={Link}
        to="/finance"
        onClick={toggleDrawer(false)}
      >
        <ListItemText primary="Finance" />
      </ListItemButton>
      <ListItemButton
        component={Link}
        to="/tools"
        onClick={toggleDrawer(false)}
      >
        <ListItemText primary="Outils" />
      </ListItemButton>
      <ListItemButton
        component={Link}
        to="/resources"
        onClick={toggleDrawer(false)}
      >
        <ListItemText primary="Ressources" />
      </ListItemButton>
    </List>
  );
};
DrawerList.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
};
export default DrawerList;
