import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DfGrid from "../../components/DfGrid";
import FileUploader from "../../components/FileUploader";
import { sendImpacterProfileAndExpenses } from "../../services/Classique/ClassiqueService";
import { transformToJson } from "../../services/Finance/FinanceHelpers";
import { ErrorAlert, SuccessAlert } from "../../components/Alert";
import DownloadExcelButton from "../../components/DownloadExcelButton";

function ClassicFileLoadingTab() {
  const [excelData, setExcelData] = useState(null);
  const [errorMode, setErrorMode] = useState(false);

  const [loading, setLoading] = useState(false);

  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function handleSendData() {
    let dataLoaded;
    if (excelData) {
      dataLoaded = {
        profile: transformToJson(excelData[0]),
        expenses: transformToJson(excelData[1]),
      };
    }

    if (dataLoaded) {
      const siren = dataLoaded.profile[0]["SIREN"];
      const year = dataLoaded.profile[0]["Année étudiée"];
      if (year && siren) {
        try {
          setErrorAlert(false);
          setSuccessAlert(false);
          setLoading(true);

          await sendImpacterProfileAndExpenses(dataLoaded, siren);

          setSuccessAlert(true);
        } catch (error) {
          setErrorAlert(true);
          setErrorMessage(error.message);
        } finally {
          setLoading(false);
        }
      } else {
        setErrorAlert(true);
        setErrorMessage(
          "L'une de ces informations est manquante : SIREN de l'entité étudiée, Année étudiée."
        );
      }
    } else {
      setErrorMode(true);
    }
  }
  return (
    <div style={{ maxWidth: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Étape 1 : Chargement du fichier excel</h1>
        <DownloadExcelButton
          path={"/assets/templates/Template_impacter_classique.xlsx"}
          filename={"impacter_classique.xlsx"}
        />
      </div>
      <FormGroup
        sx={{
          rowGap: "2rem",
        }}
      >
        <FileUploader
          onDataUpload={setExcelData}
          error={errorMode}
          numberOfSheets={2}
        />
        {excelData && (
          <div
            style={{
              width: "100%",
            }}
          >
            <DfGrid data={excelData[0]} canEdit={false} colorRows="SIREN" />
            <div style={{ marginTop: "2rem" }}>
              <DfGrid
                data={excelData[1]}
                colorRows="Code SIRET de l’établissement (14 chiffres - si inconnu, code SIREN)"
              />
            </div>
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "15%",
              minWidth: "110px",
              marginRight: "15px",
            }}
            onClick={handleSendData}
          >
            Envoyer
          </Button>
          {loading && <CircularProgress size={25} />}
        </div>
        {successAlert && (
          <SuccessAlert
            message="Les données ont été téléchargées avec succès."
            onClose={() => setSuccessAlert(false)}
          />
        )}
        {errorAlert && (
          <ErrorAlert
            message={errorMessage}
            onClose={() => setErrorAlert(false)}
          />
        )}
      </FormGroup>
    </div>
  );
}

export default ClassicFileLoadingTab;
