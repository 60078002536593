import image1_1_multiplicateurs from "../assets/resources/schema_mult.PNG";

const articlesResources = [
  {
    id: 1,
    title: "Qu’est-ce qu’un effet multiplicateur ?",
    summary: `<p><strong>Les multiplicateurs</strong> indiquent les <strong>montants de chiffre d'affaires, de valeur ajoutée et le nombre d'emplois</strong>, qui sont générés sur l'économie suite à la dépense <strong>d'un euro pour un bien ou un service</strong>.</p>`,
    content: `
        
        <p><strong>Les multiplicateurs</strong> indiquent les <strong>montants de chiffre d'affaires, de valeur ajoutée et le nombre d'emplois</strong>, qui sont générés sur l'économie suite à la dépense <strong>d'un euro pour un bien ou un service</strong>.</p>
        <p>Ils permettent d'apprécier <strong>l'effet d'entraînement</strong> d'une activité et de la <strong>comparer au reste de l'économie du territoire</strong>.</p>
        <p>Durant nos études, nous considérons trois types de multiplicateurs : </p>
        <ul>
        <li>Les <strong>effets multiplicateurs directs correspondent aux impacts directs</strong> générés relativement au chiffre d'affaire réalisé ;</li>
        <li>Les <strong>effets multiplicateurs de type I correspondent aux impacts directs et indirects</strong> générés relativement au chiffre d'affaires réalisé ;</li>
        <li>Les <strong>effets multiplicateurs de type II correspondent aux impacts totaux</strong> générés relativement au chiffre d'affaires réalisé.</li>
        </ul>
       
        <img src="${image1_1_multiplicateurs}" style="display: block; margin: 0 auto;" width="70%" alt="Schéma décrivant le fonctionnement des multiplicateurs. On y voit qu'un euro investi dans une baguette en boulangerie (impact direct) génère 30 centimes au moulin, et 15 centimes à l'agriculteur cultivant le champs de blé. On a donc 45 centimes d'impacts indirects, ce qui fait que l'effet multiplicateur de type I est de 1,45. On représente également les impacts induits qui correspondent au 20 centimes qu'un employé va pouvoir dépenser grâce à la vente de cette baguette. On obtient donc finalement un effet multiplicateur de type II de 1,65 centimes." />
        <p>Dans cet exemple, 1 € de baguette acheté auprès d'un boulanger a généré un chiffre d'affaire supplémentaire de 0,65 € sur le territoire. 1,65 est donc l'effet multiplicateur de type II de chiffre d'affaires associé.</p>
        
      `,
    images: [image1_1_multiplicateurs],
  },
];

export default articlesResources;
