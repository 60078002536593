const colors = {
  foret: "#005527",
  corail: "#e55a29",
  darkForet: "#007F43",
  darkCorail: "#ff7e52",
  ciel: "#d3e7f0",
  darkCiel: "#3E7D98",
  pollen: "#ffe47a",
  pollenLight: "#FFEEA8",
  darkPollen: "#856A00",
  darkPollenLight: "#574500",
  backgroundLight: "#F9F9FC",
  lightGrey: "#f1f1f1",
  darkLightGrey: "#262626",
};

export default colors;
