import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

function SelectList({ label, options, value, onChange, error }) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <FormControl
        sx={{
          width: "100%",
        }}
        error={error ? true : false}
      >
        <InputLabel id="select">{label}</InputLabel>
        <Select
          labelId="select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <FormHelperText sx={{ marginBottom: -3 }}>
            Veuillez sélectionner une valeur
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

SelectList.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
};

SelectList.defaultProps = {
  label: "",
};

export default SelectList;
