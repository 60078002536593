import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import colors from "../../utils/style/colors";
import { useContext } from "react";
import { ThemeContext } from "../../utils/context";
import PropTypes from "prop-types";

function BasicTable({ rows, header }) {
  const { theme } = useContext(ThemeContext);

  const columnWidth = `${100 / header.length}%`;

  return (
    <TableContainer component={Paper} style={{ width: "auto", margin: "auto" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {header.map((headCell, index) => (
              <TableCell
                key={index}
                align="center"
                style={{
                  width: columnWidth,
                  color: theme === "light" ? colors.corail : colors.darkCorail,
                  backgroundColor:
                    theme === "light" ? colors.lightGrey : colors.darkLightGrey,
                }}
              >
                {headCell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.values(row).map((cell, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  align="center"
                  style={{
                    width: columnWidth,
                    fontWeight: cellIndex === 0 ? 500 : "normal",
                    backgroundColor:
                      cellIndex === 0
                        ? theme === "light"
                          ? colors.lightGrey
                          : colors.darkLightGrey
                        : null,
                  }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
BasicTable.propTypes = {
  rows: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
};

export default BasicTable;
