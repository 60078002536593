import React, { useContext, useMemo } from "react";
import KeyFigure from "../KeyFigure";
import PropTypes from "prop-types";
import { ThemeContext } from "../../utils/context";
import WhiteMap from "../../assets/map-white.png";
import BlackMap from "../../assets/map-black.png";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";

const FranceMap = React.memo(({ profileGroup }) => {
  const { theme } = useContext(ThemeContext);
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 900px)" });

  const memoizedKeyFigure = useMemo(
    () => (
      <KeyFigure
        value={parseFloat(profileGroup.nombre_de_filiales)}
        unit={"filiales"}
        description={"étudiées"}
        background={true}
        decimalPlaces={0}
      />
    ),
    [profileGroup]
  );

  return (
    <Box
      sx={{
        textAlign: "center",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          opacity: 0.09,
          position: "absolute",
          zIndex: 0,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <img
          src={theme === "light" ? BlackMap : WhiteMap}
          alt=""
          width="100%"
          height="100%"
          style={{
            width: isDesktopOrLaptop ? "auto" : "100%",
            height: isDesktopOrLaptop ? "100%" : "auto",
          }}
        />
      </div>
      <div
        style={{
          zIndex: 1,
          width: "100%",
        }}
      >
        <span
          style={{
            fontSize: "3rem",
            fontWeight: "bold",
          }}
        >
          {profileGroup.denomination_groupe}
        </span>

        {memoizedKeyFigure}
      </div>
    </Box>
  );
});

FranceMap.propTypes = {
  profileGroup: PropTypes.object.isRequired,
};

export default FranceMap;
