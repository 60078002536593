import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export function ErrorAlert({ message, onClose }) {
  return (
    <Alert role="alert" severity="error" onClose={onClose}>
      <AlertTitle>Erreur</AlertTitle>
      {message}
    </Alert>
  );
}

export function SuccessAlert({ message, onClose }) {
  return (
    <Alert role="alert" severity="success" onClose={onClose}>
      <AlertTitle>Succès</AlertTitle>
      {message}
    </Alert>
  );
}

export function WarningAlert({ message }) {
  return (
    <Alert role="alert" severity="warning">
      <AlertTitle>Attention</AlertTitle>
      {message}
    </Alert>
  );
}
