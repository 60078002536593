import axios from "axios";

const TYPE_PROFILAGE_PRECONSOLIDATION = {
  denomination_groupe: "str",
  siren_groupe: "str",
  denomination_filiale: "str",
  siren_filiale: "str",
  code_naf: "str",
  intitule_naf: "str",
  warning: "json",
  code_consolidation: "str",
  isClassif: "bool",
  code_classification: "str",
  chiffre_affaires_net: "float",
  achats_de_marchandises_y_compris_droits_de_douane: "float",
  achats_BS: "float",
  ventes_de_marchandises: "float",
  ventes_BS: "float",
  variation_de_stock: "float",
  salaires_et_traitements: "float",
  effectifs: "float",
  annee: "str",
  production_vendue_biens: "float",
  production_vendue_services: "float",
  production_stockee: "float",
  production_immobilisee: "float",
  subventions_exploitation: "float",
  variation_de_stock_marchandises: "float",
  achats_de_matieres_premieres_et_autres_approvisionnements: "float",
  autres_achats_et_charges_externes: "float",
  impots_taxes_versements_assimiles: "float",
  charges_sociales: "float",
  production: "float",
  consommations_intermediaires: "float",
  VAB: "float",
  taux_de_valeur_ajoutee: "str",
  salaires_et_charges: "float",
  impots_et_subventions: "float",
};

export const TYPE_PROFILAGE = {
  denomination_groupe: "str",
  siren_groupe: "str",
  achats_de_marchandises_y_compris_droits_de_douane: "float",
  achats_BS: "float",
  ventes_de_marchandises: "float",
  ventes_BS: "float",
  denomination_filiale: "str",
  siren_filiale: "str",
  code_naf: "str",
  intitule_naf: "str",
  warning: "json",
  code_consolidation: "str",
  isClassif: "bool",
  code_classification: "str",
  chiffre_affaires_net: "float",
  CIN: "float",
  part_CIN: "float",
  part_domestique: "float",
  variation_de_stock: "float",
  salaires_et_traitements: "float",
  effectifs: "float",
  annee: "str",
  production_vendue_biens: "float",
  production_vendue_services: "float",
  production_stockee: "float",
  production_immobilisee: "float",
  subventions_exploitation: "float",
  variation_de_stock_marchandises: "float",
  achats_de_matieres_premieres_et_autres_approvisionnements: "float",
  autres_achats_et_charges_externes: "float",
  impots_taxes_versements_assimiles: "float",
  charges_sociales: "float",
  production: "float",
  consommations_intermediaires: "float",
  VAB: "float",
  taux_de_valeur_ajoutee: "str",
  salaires_et_charges: "float",
  impots_et_subventions: "float",
};

const convertirType = (value, type) => {
  switch (type) {
    case "str":
      return String(value);
    case "bool":
      return Boolean(value);
    case "float":
      return parseFloat(value);
    default:
      return value;
  }
};

const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "http://localhost:8123";

const getProfilagePreconsoForYear = async (year, siren) => {
  try {
    let response;
    if (siren && siren !== "") {
      response = await axios.get(`${BASE_URL}/profilage/${siren}/${year}`);
    } else {
      response = await axios.get(`${BASE_URL}/profilage/${year}`);
    }
    const profilage = response.data;

    const profilageAvecTypes = profilage.map((profil) => {
      const profilAvecTypes = {};
      Object.keys(TYPE_PROFILAGE_PRECONSOLIDATION).forEach((key) => {
        if (profil.hasOwnProperty(key)) {
          const type = TYPE_PROFILAGE_PRECONSOLIDATION[key];
          profilAvecTypes[key] = convertirType(profil[key], type);
        } else {
          profilAvecTypes[key] = null;
        }
      });
      return profilAvecTypes;
    });

    if (profilage.length === 0) {
      throw new Error("Il n'y a pas de profils pour cette année.");
    } else {
      return profilageAvecTypes;
    }
  } catch (error) {
    console.error("Error:", error);
    let errorMessage =
      "Une erreur est survenue lors du chargement des données.";
    if (error.message === "Il n'y a pas de profils pour cette année.") {
      throw error;
    } else if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }
    throw new Error(errorMessage);
  }
};

const editProfilage = async (df) => {
  try {
    const response = await axios.patch(`${BASE_URL}/profilage/`, df);
    if (response.status === 200) {
      return "Données mises à jour avec succès !";
    } else {
      throw new Error(
        "Une erreur est survenue lors de la mise à jour des données."
      );
    }
  } catch (error) {
    console.error("Error:", error);

    let errorMessage =
      "Une erreur est survenue lors de la mise à jour des données.";

    if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    } else if (error.response && error.response.status === 413) {
      errorMessage = "Le tableau de données est trop lourd.";
    } else if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    }

    throw new Error(errorMessage);
  }
};

const getAllConsolidatedProfiles = async (year, siren) => {
  try {
    let response;
    if (siren && siren !== "") {
      response = await axios.get(
        `${BASE_URL}/profilage/consolidated/${siren}/${year}`
      );
    } else {
      response = await axios.get(`${BASE_URL}/profilage/consolidated/${year}`);
    }
    const consolidatedProfiles = response.data;

    const profilageAvecTypes = consolidatedProfiles.map((profile) => {
      const profilAvecTypes = {};
      Object.keys(TYPE_PROFILAGE).forEach((key) => {
        if (profile.hasOwnProperty(key)) {
          const type = TYPE_PROFILAGE[key];
          profilAvecTypes[key] = convertirType(profile[key], type);
        } else {
          profilAvecTypes[key] = null;
        }
      });
      return profilAvecTypes;
    });

    if (consolidatedProfiles.length === 0) {
      throw new Error("Il n'y a pas de profils consolidés pour cette année.");
    } else {
      return profilageAvecTypes;
    }
  } catch (error) {
    console.error("Error:", error);
    let errorMessage =
      "Une erreur est survenue lors du chargement des données.";
    if (
      error.message === "Il n'y a pas de profils consolidés pour cette année."
    ) {
      throw error;
    } else if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }
    throw new Error(errorMessage);
  }
};

const consolidateData = async (df) => {
  try {
    const response = await axios.patch(`${BASE_URL}/profilage/consolidate`, df);
    if (response.status === 200) {
      return "Consolidation effectuée avec succès !";
    } else {
      throw new Error(
        "Une erreur est survenue lors de la consolidation des données."
      );
    }
  } catch (error) {
    console.error("Error:", error);

    let errorMessage =
      "Une erreur est survenue lors de la mise à jour des données.";

    if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    } else if (error.response && error.response.status === 413) {
      errorMessage = "Le tableau de données est trop lourd.";
    } else if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    }

    throw new Error(errorMessage);
  }
};

const sendProfilageData = async (df, year, force) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/profilage/compute/${year}?force=${force}`,
      df
    );
    if (response.status === 201) {
      return "Données envoyées avec succès !";
    } else {
      throw new Error("Une erreur est survenue lors de l'envoi des données.");
    }
  } catch (error) {
    console.error("Error:", error);

    let errorMessage = "Une erreur est survenue lors de l'envoi des données.";

    if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    } else if (error.response && error.response.status === 413) {
      errorMessage = "Le tableau de données est trop lourd.";
    } else if (error.response && error.response.status === 502) {
      errorMessage = `Erreur "502 Bad Gateway". Cette erreur est survenue lors de la récupération des données de l'INPI.`;
    } else if (error.response && error.response.status === 400) {
      errorMessage +=
        " Les SIREN correspondant aux indices suivants n'ont pas la bonne syntaxe : ";
      JSON.parse(error.response.data.message).errors.forEach((error) => {
        errorMessage += `${error.idx + 1} ; `;
      });
    }

    throw new Error(errorMessage);
  }
};

const getAllSirenGroupForYear = async (year, query, data) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/profilage/all-siren/${data}/${year}?q=${query}`
    );
    const sirens = response.data;
    return sirens;
  } catch (error) {
    throw error;
  }
};

const getProfileGroup = async (year) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/profilage/all-profil/${year}`
    );
    const profileGroup = response.data;
    if (profileGroup.length === 0) {
      throw new Error("Il n'y a pas de profils de groupe pour cette année.");
    } else {
      return profileGroup;
    }
  } catch (error) {
    console.error("Error:", error);
    let errorMessage =
      "Une erreur est survenue lors du téléchargement des données.";
    if (
      error.message === "Il n'y a pas de profils de groupe pour cette année."
    ) {
      throw error;
    } else if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }

    throw new Error(errorMessage);
  }
};

const getProfileGroupBySiren = async (year, siren) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/profilage/group-profil/${siren}/${year}`
    );
    const profileGroup = response.data;
    if (profileGroup.length === 0) {
      throw new Error(
        "Les retombées de ce groupe ne sont pas calculées pour cette année."
      );
    } else {
      return profileGroup;
    }
  } catch (error) {
    console.error("Error:", error);
    let errorMessage =
      "Une erreur est survenue lors du téléchargement des données.";
    if (
      error.message ===
      "Les retombées de ce groupe ne sont pas calculées pour cette année."
    ) {
      throw error;
    } else if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }

    throw new Error(errorMessage);
  }
};

const getUpdatedilanForYear = async (year) => {
  try {
    const response = await axios.get(`${BASE_URL}/bilan-inpi/updated/${year}`);
    const bilans = response.data;

    if (bilans.length === 0) {
      throw new Error(
        "Aucun bilan n'a été récupéré pour cette année. Avez-vous chargé les profils ?"
      );
    } else {
      return bilans;
    }
  } catch (error) {
    console.error("Error :", error);
    let errorMessage =
      "Une erreur est survenue lors du téléchargement des données.";
    if (
      error.message ===
      "Aucun bilan n'a été récupéré pour cette année. Avez-vous chargé les profils ?"
    ) {
      throw error;
    } else if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }

    throw new Error(errorMessage);
  }
};

const editBilan = async (df) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/bilan-inpi/correction/`,
      df
    );
    if (response.status === 200) {
      return "Données mises à jour avec succès !";
    } else {
      throw new Error(
        "Une erreur est survenue lors de la mise à jour des données."
      );
    }
  } catch (error) {
    console.error("Error:", error);

    let errorMessage =
      "Une erreur est survenue lors de la mise à jour des données.";

    if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    } else if (error.response && error.response.status === 413) {
      errorMessage = "Le tableau de données est trop lourd.";
    } else if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    }

    throw new Error(errorMessage);
  }
};

export {
  sendProfilageData,
  getProfileGroup,
  getProfilagePreconsoForYear,
  editProfilage,
  consolidateData,
  getAllConsolidatedProfiles,
  getProfileGroupBySiren,
  getUpdatedilanForYear,
  editBilan,
  getAllSirenGroupForYear,
};
