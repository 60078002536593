import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "http://localhost:8123";
const getProfileForYearAndSiren = async (year, siren) => {
  try {
    const response = await axios.get(`${BASE_URL}/impacter/${year}/${siren}`);

    const profile = response.data;

    if (profile.length === 0) {
      throw new Error(
        `Il n'y a pas de profil enregistré pour le siren ${siren} et l'année ${year}.`
      );
    } else {
      return profile;
    }
  } catch (error) {
    console.error("Error:", error);
    let errorMessage =
      "Une erreur est survenue lors du chargement des données.";
    if (
      error.message ===
      `Il n'y a pas de profil enregistré pour le siren ${siren} et l'année ${year}.`
    ) {
      throw error;
    } else if (
      error.response &&
      (error.response.status === 404 ||
        error.response.status === 409 ||
        error.response.status === 400)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }
    throw new Error(errorMessage);
  }
};

const getAllExpensesForSirenForYear = async (year, siren) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/impacter/expense/${year}/${siren}`
    );

    const expenses = response.data;

    if (expenses.length === 0) {
      throw new Error(
        `Il n'y a pas de dépenses enregistrées pour le siren ${siren} et l'année ${year}.`
      );
    } else {
      return expenses;
    }
  } catch (error) {
    console.error("Error:", error);
    let errorMessage =
      "Une erreur est survenue lors du chargement des données.";
    if (
      error.message ===
      `Il n'y a pas de dépenses enregistrées pour le siren ${siren} et l'année ${year}.`
    ) {
      throw error;
    } else if (
      error.response &&
      (error.response.status === 404 ||
        error.response.status === 409 ||
        error.response.status === 400)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    }
    throw new Error(errorMessage);
  }
};

const sendImpacterProfileAndExpenses = async (df, siren) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/impacter/import/${siren}`,
      df
    );
    if (response.status === 201) {
      return "Données envoyées avec succès !";
    } else {
      throw new Error("Une erreur est survenue lors de l'envoi des données.");
    }
  } catch (error) {
    console.error("Error:", error);

    let errorMessage = "Une erreur est survenue lors de l'envoi des données.";

    if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    } else if (error.response && error.response.status === 413) {
      errorMessage = "Le tableau de données est trop lourd.";
    } else if (
      error.response &&
      (error.response.status === 400 || error.response.status === 503)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 502) {
      errorMessage =
        "Une erreur est survenue lors du chargement des codes NAF des fournisseurs sur l'API Sirene. Assurez-vous d'avoir renseigné le bon SIRET.";
    }

    throw new Error(errorMessage);
  }
};

const getAllSirenGroupForYear = async (year, query) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/impacter/all-siren/${year}?q=${query}`
    );
    const sirens = response.data;
    return sirens;
  } catch (error) {
    throw error;
  }
};

const updateImpacter = async (df, dataUpdated) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/impacter/${dataUpdated}`,
      df
    );
    if (response.status === 200) {
      return "Données envoyées avec succès !";
    } else {
      throw new Error("Une erreur est survenue lors de l'envoi des données.");
    }
  } catch (error) {
    console.error("Error:", error);

    let errorMessage = "Une erreur est survenue lors de l'envoi des données.";

    if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    } else if (error.response && error.response.status === 413) {
      errorMessage = "Le tableau de données est trop lourd.";
    } else if (error.response && error.response.status === 400) {
      errorMessage = error.response.data.message;
    }

    throw new Error(errorMessage);
  }
};

const computeImpacter = async (siren, year, territory) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}/impacter/compute/${territory}/${siren}/${year}`
    );
    if (response.status === 200) {
      return "Données calculées avec succès !";
    } else {
      throw new Error("Une erreur est survenue lors de l'envoi des données.");
    }
  } catch (error) {
    console.error("Error:", error);

    let errorMessage = "Une erreur est survenue lors de l'envoi des données.";

    if (
      error.response &&
      (error.response.status === 404 || error.response.status === 409)
    ) {
      errorMessage = error.response.data.message;
    } else if (error.response && error.response.status === 500) {
      errorMessage =
        "Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.";
    } else if (error.response && error.response.status === 400) {
      errorMessage = error.response.data.message;
    }

    throw new Error(errorMessage);
  }
};

const deleteExpenses = async (ids) => {
  try {
    const response = await axios.delete(`${BASE_URL}/impacter/expenses`, {
      data: ids,
    });
    if (response.status === 200) {
      return "Données calculées avec succès !";
    } else {
      throw new Error(
        "Une erreur est survenue lors de la suppression des données."
      );
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export {
  sendImpacterProfileAndExpenses,
  getProfileForYearAndSiren,
  updateImpacter,
  getAllExpensesForSirenForYear,
  computeImpacter,
  deleteExpenses,
  getAllSirenGroupForYear,
};
