import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DrawerList from "../DrawerList";

const MenuDrawer = ({ toggleDrawer, drawerOpen, setConfirmLogoutOpen }) => {
  return (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <DrawerList toggleDrawer={toggleDrawer} />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setConfirmLogoutOpen(true)}
        style={{ marginTop: "20px", width: "80%", marginLeft: "10%" }}
      >
        Déconnexion
      </Button>
    </Drawer>
  );
};

export default MenuDrawer;
