import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import articlesResources from "../../data/articlesResources";
import { ContainerFullWidth } from "../../utils/style/Atoms";
import Button from "@mui/material/Button";
import UndoIcon from "@mui/icons-material/Undo";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Error from "../../components/Error";

const StyledTitle = styled("h1")(({ theme }) => ({
  position: "relative",
  marginLeft: "15px",
  display: "inline-block",
}));

const IconContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: "-50px",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  zIndex: -1,
}));

const BackgroundIcon = styled(HelpOutlineOutlinedIcon)(({ theme }) => ({
  fontSize: "80px",
  opacity: 0.2,
  color: theme.palette.secondary.main,
}));

const ArticleResourceDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const article = articlesResources.find(
    (article) => article.id === parseInt(id)
  );

  if (!article) {
    return <Error />;
  }

  return (
    <ContainerFullWidth>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
          <StyledTitle>
            {article.title}
            <IconContainer>
              <BackgroundIcon />
            </IconContainer>
          </StyledTitle>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Grid container justifyContent="flex-end">
            <Button
              startIcon={<UndoIcon />}
              variant="outlined"
              color="greyButton"
              onClick={goBack}
            >
              Retour
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div dangerouslySetInnerHTML={{ __html: article.content }} />
    </ContainerFullWidth>
  );
};

export default ArticleResourceDetail;
