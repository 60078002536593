import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import KeyFigure from "../../components/KeyFigure";
import { useMediaQuery } from "react-responsive";
import { formatValue } from "../../services/Finance/FinanceHelpers";
import FranceMap from "../../components/FranceMap";

function GroupImpactData({ profileGroup, unit }) {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 900px)" });

  return (
    <>
      <Container>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          direction={isDesktopOrLaptop ? "row" : "column"}
        >
          <Grid
            item
            xs={isDesktopOrLaptop ? 6 : 12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <FranceMap profileGroup={profileGroup} />
          </Grid>
          <Grid
            item
            xs={isDesktopOrLaptop ? 6 : 12}
            display="flex"
            justifyContent="center"
            style={{ width: "100%" }}
          >
            <Grid
              container
              spacing={1}
              direction={isDesktopOrLaptop ? "column" : "row"}
              justifyContent="center"
              zIndex={1}
              alignItems={isDesktopOrLaptop ? "flex-start" : "center"}
            >
              <Grid
                item
                xs={isDesktopOrLaptop ? 4 : 12}
                display="flex"
                justifyContent="center"
              >
                <KeyFigure
                  value={parseFloat(formatValue(profileGroup.CA_FR, unit))}
                  unit={`${unit}`}
                  description={"de chiffre d'affaires"}
                  decimalPlaces={1}
                  background={isDesktopOrLaptop ? false : true}
                />
              </Grid>
              <Grid
                item
                xs={isDesktopOrLaptop ? 4 : 12}
                display="flex"
                justifyContent="center"
              >
                <KeyFigure
                  value={parseFloat(formatValue(profileGroup.direct_VAB, unit))}
                  unit={`${unit}`}
                  description={"de VAB"}
                  decimalPlaces={1}
                  background={isDesktopOrLaptop ? false : true}
                />
              </Grid>
              <Grid
                item
                xs={isDesktopOrLaptop ? 4 : 12}
                display="flex"
                justifyContent="center"
              >
                <KeyFigure
                  value={parseFloat(profileGroup.direct_emploi.toFixed(0))}
                  unit={"emplois directs"}
                  description={"générés"}
                  decimalPlaces={0}
                  background={isDesktopOrLaptop ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

GroupImpactData.propTypes = {
  profileGroup: PropTypes.object.isRequired,
};

export default GroupImpactData;
