function downloadXLSX(dataToDownload, sheetName, fileName) {
  import("xlsx").then((XLSX) => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.aoa_to_sheet(dataToDownload);

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    XLSX.writeFile(workbook, fileName);
  });
}

export { downloadXLSX };
