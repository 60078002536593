import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../provider/authProvider";

const LogoutPage = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  useEffect(() => {
    const handleLogout = () => {
      signOut();
      navigate("/", { replace: true });
    };
    handleLogout();
  });

  return <>Logout Page</>;
};

export default LogoutPage;
