import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import JobEstimateTab from "../../containers/JobEstimateTab";
import MultipliersTab from "../../containers/MultipliersTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Tools() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        maxWidth: "100%",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          marginRight: "30px",
          marginTop: "50px",
          minWidth: "15%",
        }}
      >
        <Tab label="Estimation des emplois" {...a11yProps(0)} />
        <Tab label="Multiplicateurs" {...a11yProps(1)} />
      </Tabs>
      <Box
        sx={{
          width: "100%",
          maxWidth: "calc(100% - 18%)",
        }}
      >
        <TabPanel value={value} index={0}>
          <JobEstimateTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MultipliersTab />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default Tools;
