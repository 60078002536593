import { ContainerFullWidth } from "../../utils/style/Atoms";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Link } from "react-router-dom";
import impacterClassique from "../../assets/impacter-classique.png";
import impacterFinance from "../../assets/impacter-finance.png";
import { useContext } from "react";
import { ThemeContext } from "../../utils/context";

function Home() {
  const { theme } = useContext(ThemeContext);
  const itemData = [
    {
      img: impacterClassique,
      title: "ImpacTer Classique",
      link: "/classique",
    },
    {
      img: impacterFinance,
      title: "Impacter Finance",
      link: "/finance",
    },
  ];
  return (
    <ContainerFullWidth>
      <h1>Bienvenue sur l'outil de calcul d'impacts ImpacTer !</h1>
      <ImageList
        sx={{
          width: "100%",
          height: "auto",
          overflow: "visible",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {itemData.map((item) => (
          <div
            style={{
              position: "relative",
              overflow: "visible",
              width: "calc(50% - 16px)",
              height: "auto",
            }}
            key={item.img}
          >
            <Link
              to={item.link}
              style={{
                textDecoration: "none",
                display: "block",
                height: "100%",
              }}
            >
              <ImageListItem
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                  position: "relative",
                  width: "100%",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  backgroundColor: theme === "light" ? "#fff" : "#101010",
                  "&:hover": {
                    transform: "scale(1.03)",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                  },
                }}
              >
                <img
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    maxHeight: "60vh",
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
                <ImageListItemBar
                  title={item.title}
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                  }}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                      aria-label={`Aller vers l'onglet ${item.title}`}
                    >
                      <ArrowCircleRightIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            </Link>
          </div>
        ))}
      </ImageList>
    </ContainerFullWidth>
  );
}

export default Home;
