import { StrictMode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { ThemeProviderVL } from "./utils/context";
import GlobalStyle from "./utils/style/GlobalStyle";
import RoutesApp from "./routes";
import AuthProvider from "./provider/authProvider";

const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <AuthProvider>
      <Router>
        <ThemeProviderVL>
          <GlobalStyle />
          <RoutesApp />
        </ThemeProviderVL>
      </Router>
    </AuthProvider>
  </StrictMode>
);
