import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import RestoreIcon from "@mui/icons-material/Restore";
import { useMemo, useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../utils/context";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional Theme applied to the grid
import Switch from "@mui/material/Switch";

function DfGrid({
  data,
  canEdit,
  editableCols,
  onDataChange,
  colorRows,
  toggleFullScreen,
  fullScreen,
  enableFullScreen,
  canFilter,
  displayPagination,
  rowSize,
  columnsToDisplay,
  canDelete,
  colDelete,
  setRowsToDelete,
}) {
  const { theme } = useContext(ThemeContext);

  const [gridData, setGridData] = useState([]);
  const [groupNumbers, setGroupNumbers] = useState([]);
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [deletedRows, setDeletedRows] = useState([]);

  const injectGlobalStyles = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      .ag-center-cols-viewport {
        min-height: unset !important;
      }
    `;
    document.head.appendChild(style);
  };
  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIds = selectedRows.map((row) => row.id);
    setSelectedRowIds(selectedIds);
  };
  const handleDeleteSelectedRows = () => {
    const currentSelectedRowIds = [...selectedRowIds];

    const rowsToDelete = gridData.filter((row) =>
      currentSelectedRowIds.includes(row.id)
    );

    setDeletedRows((prev) => [
      ...prev,
      ...rowsToDelete.map((row) => ({
        ...row,
        originalIndex: gridData.findIndex((r) => r.id === row.id),
      })),
    ]);

    const updatedData = gridData.filter(
      (row) => !currentSelectedRowIds.includes(row.id)
    );

    setGridData(updatedData);

    setRowsToDelete((prev) => [...prev, ...currentSelectedRowIds]);

    setSelectedRowIds([]);
  };

  const handleRestoreDeletedRows = () => {
    const restoredRows = deletedRows.sort(
      (a, b) => a.originalIndex - b.originalIndex
    );

    setGridData((prevData) => {
      const dataWithRestoredRows = [...prevData];
      restoredRows.forEach((row) => {
        dataWithRestoredRows.splice(row.originalIndex, 0, row);
      });
      return dataWithRestoredRows;
    });
    setDeletedRows([]);
    setRowsToDelete([]);
  };

  const columns = useMemo(() => {
    const defaultColumns = displayedColumns.map((colName) => ({
      field: colName,
      headerName: colName.replace(/_/g, " "),
      editable: canEdit && editableCols.includes(colName),
      tooltipValueGetter: (p) => {
        if (p.value != null) {
          if (typeof p.value === "object") {
            return JSON.stringify(p.value);
          }
          return p.value.toString();
        }
        return "";
      },
      valueFormatter: (params) => {
        const value = params.value;
        if (typeof value === "number" && Number.isFinite(value)) {
          return value.toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 20,
          });
        }
        if (typeof value === "object" && value !== null)
          return JSON.stringify(value);
        return value;
      },
    }));

    if (canDelete && colDelete) {
      const colDeleteIndex = displayedColumns.indexOf(colDelete);
      if (colDeleteIndex !== -1) {
        defaultColumns[colDeleteIndex] = {
          headerName: colDelete,
          field: colDelete,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        };
      }
    }

    return defaultColumns;
  }, [displayedColumns, canEdit, editableCols, canDelete, colDelete]);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: canFilter,
    };
  }, [canFilter]);

  const getGroupNumbers = (data, color) => {
    let groupNumbers = [];
    let currentGroup = 0;
    let prevValue = null;
    data.forEach((row) => {
      if (row[color] !== prevValue) {
        currentGroup++;
        prevValue = row[color];
      }
      groupNumbers.push(currentGroup);
    });
    return groupNumbers;
  };

  const rowClassRules = useMemo(() => {
    return {
      "even-row-dark": (params) => {
        const sortedRowIndex = gridData.findIndex(
          (row) => row.id === params.data.id
        );
        return theme === "dark" && groupNumbers[sortedRowIndex] % 2 === 0;
      },
      "even-row": (params) => {
        const sortedRowIndex = gridData.findIndex(
          (row) => row.id === params.data.id
        );
        return theme === "light" && groupNumbers[sortedRowIndex] % 2 === 0;
      },
    };
  }, [gridData, groupNumbers, theme]);

  const handleCellValueChanged = (event) => {
    const updatedRow = [];
    const rowIndex = gridData.findIndex((row) => row.id === event.data.id);
    if (rowIndex >= 0) {
      updatedRow[0] = {
        ...gridData[rowIndex],
        [event.column.colId]: event.newValue,
      };
    }
    if (onDataChange) onDataChange(Object.values(updatedRow[0]), gridData);
  };

  useEffect(() => {
    if (!columnsToDisplay || columnsToDisplay.length === 0) {
      if (data && data.length > 0) setDisplayedColumns(data[0]);
    } else setDisplayedColumns(columnsToDisplay);

    injectGlobalStyles();

    const rows = data.slice(1).map((row, index) => ({
      id: index,
      ...row.reduce((acc, curr, index) => {
        acc[data[0][index]] = curr;
        return acc;
      }, {}),
    }));

    const sortedRows = rows.sort((a, b) => {
      const propertyA = a[colorRows];
      const propertyB = b[colorRows];

      if (propertyA < propertyB) return -1;
      if (propertyA > propertyB) return 1;
      return 0;
    });

    setGridData(sortedRows);
    const classNumbers = getGroupNumbers(sortedRows, colorRows);
    setGroupNumbers(classNumbers);
  }, [data, colorRows, columnsToDisplay]);

  return (
    <div
      className={theme === "light" ? "ag-theme-quartz" : "ag-theme-quartz-dark"}
    >
      <div
        style={{
          height: enableFullScreen ? "95vh" : "inherit",
        }}
      >
        <AgGridReact
          domLayout={!enableFullScreen ? "autoHeight" : null}
          showColumnMenu={true}
          rowData={gridData}
          enableCellTextSelection={true}
          ensureDomOrder={true}
          columnDefs={columns}
          onCellValueChanged={handleCellValueChanged}
          rowHeight={rowSize}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          rowClassRules={rowClassRules}
          suppressRowClickSelection={true}
          pagination={displayPagination}
          suppressDragLeaveHidesColumns={true}
          paginationPageSize={
            displayPagination ? (!enableFullScreen ? 20 : 100) : null
          }
          paginationPageSizeSelector={
            displayPagination ? [20, 50, 100, 1000, 2000] : null
          }
          columnHoverHighlight={false}
          tooltipInteraction={true}
          tooltipShowDelay={500}
          onSelectionChanged={onSelectionChanged}
        />
        {enableFullScreen ? (
          <div style={{ marginLeft: "1%" }}>
            {fullScreen ? "Plein écran : activé" : "Plein écran : désactivé"}
            <Switch onClick={() => toggleFullScreen()} />
          </div>
        ) : null}
        {canDelete ? (
          <Button
            sx={{
              marginTop: "1rem",
            }}
            onClick={handleDeleteSelectedRows}
            startIcon={<DeleteSweepIcon />}
            color="secondary"
            variant="contained"
            disabled={selectedRowIds.length === 0}
          >
            Supprimer
          </Button>
        ) : null}
        {deletedRows.length > 0 && (
          <Button
            sx={{
              marginTop: "1rem",
              marginLeft: "1rem",
            }}
            onClick={handleRestoreDeletedRows}
            startIcon={<RestoreIcon />}
            color="primary"
            variant="contained"
          >
            Restaurer
          </Button>
        )}
      </div>
    </div>
  );
}

DfGrid.propTypes = {
  data: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  editableCols: PropTypes.array,
  onDataChange: PropTypes.func,
  colorRows: PropTypes.string,
  toggleFullScreen: PropTypes.func,
  fullScreen: PropTypes.bool,
  enableFullScreen: PropTypes.bool,
  canFilter: PropTypes.bool,
  displayPagination: PropTypes.bool,
  rowSize: PropTypes.number,
  columnsToDisplay: PropTypes.array,
  canDelete: PropTypes.bool,
  colDelete: PropTypes.string,
};

DfGrid.defaultProps = {
  canEdit: false,
  canFilter: true,
  colorRows: "",
  enableFullScreen: false,
  fullScreen: false,
  displayPagination: true,
  rowSize: 25,
  columnsToDisplay: [],
  canDelete: false,
  colDelete: "",
};

export default DfGrid;
