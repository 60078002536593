import { useState } from "react";
import NumberInput from "../NumberInput";
import SelectList from "../SelectList";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import { ErrorAlert } from "../Alert";
import SearchGroupAutocomplete from "../SearchGroupAutocomplete";
import { getAllSirenGroupForYear } from "../../services/Classique/ClassiqueService";

function ClassicForm({
  year,
  setYear,
  maxYear,
  setSirenGroup,
  territory,
  setTerritory,
  formValid,
  handleResearch,
  loading,
  errorAlert,
  errorMessage,
  setErrorAlert,
}) {
  const [displayedSirenGroup, setDisplayedSirenGroup] = useState("");

  async function fetchSirenOptions(year, query) {
    return await getAllSirenGroupForYear(year, query);
  }

  function handleSirenGroupChange(siren, displayedValue) {
    setSirenGroup(siren);
    setDisplayedSirenGroup(displayedValue);
  }

  return (
    <FormGroup
      sx={{
        rowGap: "2rem",
        marginBottom: "2rem",
      }}
    >
      <NumberInput
        label="Année d'étude"
        value={year}
        onChange={(year) => setYear(year)}
        minValue={2015}
        maxValue={maxYear}
        error={!formValid && !year}
      />
      <SearchGroupAutocomplete
        year={year}
        value={displayedSirenGroup}
        onChange={handleSirenGroupChange}
        fetchOptions={fetchSirenOptions}
        label="Choisissez un groupe :"
        formValid={formValid}
      />
      <SelectList
        label="Territoire"
        options={["France"]}
        value={territory}
        onChange={(selectedTerritory) => {
          setTerritory(selectedTerritory);
        }}
        error={!formValid && !territory}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          sx={{
            width: "15%",
            marginRight: "1rem",
            minWidth: "110px",
          }}
          onClick={handleResearch}
        >
          Rechercher
        </Button>
        {loading && <CircularProgress size={25} />}
      </div>
      {errorAlert && (
        <ErrorAlert
          message={errorMessage}
          onClose={() => setErrorAlert(false)}
        />
      )}
    </FormGroup>
  );
}

export default ClassicForm;
