import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, useLocation } from "react-router-dom";

const HeaderNavigationTabs = ({ theme }) => {
  const location = useLocation();

  const tabs = [
    { label: "Accueil", path: "/" },
    { label: "Classique", path: "/classique" },
    { label: "Finance", path: "/finance" },
    { label: "Outils", path: "/tools" },
    { label: "Ressources", path: "/resources" },
  ];

  const getActiveTabValue = () => {
    const currentPath = location.pathname;
    for (let tab of tabs) {
      if (currentPath === tab.path || currentPath.startsWith(tab.path + "/")) {
        return tab.path;
      }
    }
    return "/";
  };

  return (
    <Tabs
      value={getActiveTabValue()}
      indicatorColor="primary"
      textColor="inherit"
      sx={{ display: { xs: "none", md: "flex" } }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.path}
          label={tab.label}
          value={tab.path}
          component={Link}
          to={tab.path}
          sx={tabStyles(theme, tab.path)}
        />
      ))}
    </Tabs>
  );
};

const tabStyles = (theme, path) => ({
  textTransform: "none",
  fontSize: "1.1rem",
  borderTopLeftRadius: "15px",
  borderTopRightRadius: "15px",
  position: "relative",
  "&::after": {
    content: "''",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: 0,
    width: "30%",
    height: "2px",
    backgroundColor: theme === "light" ? "#000" : "#FFF",
    opacity: 0,
    transition: "opacity 0.2s ease",
  },
  "&:hover::after": {
    opacity: 1,
  },
});

export default HeaderNavigationTabs;
