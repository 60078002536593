import { useContext } from "react";
import { ThemeContext } from "../../utils/context";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

function Footer() {
  const { toggleTheme, theme } = useContext(ThemeContext);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        color: "secondary.main",
      }}
    >
      Passer en mode {theme === "dark" ? "clair" : "sombre"} :
      <IconButton onClick={() => toggleTheme()} color="inherit">
        {theme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
      </IconButton>
    </Box>
  );
}

export default Footer;
