import NumberInput from "../../components/NumberInput";
import DfGrid from "../../components/DfGrid";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import {
  editBilan,
  getUpdatedilanForYear,
} from "../../services/Finance/FinanceService";
import {
  transformToArrayProfiles,
  transformToJson,
} from "../../services/Finance/FinanceHelpers";
import { ErrorAlert, SuccessAlert } from "../../components/Alert";

function CheckBilanInpiTab() {
  const maxYear = parseInt(new Date().getFullYear() - 1);

  const [year, setYear] = useState(maxYear);
  const [formValid, setFormValid] = useState(true);

  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [errorUpdateAlert, setErrorUpdateAlert] = useState(false);
  const [errorUpdateMessage, setErrorUpdateMessage] = useState("");
  const [successUpdateAlert, setSuccessUpdateAlert] = useState(false);

  const [bilansInpi, setBilansInpi] = useState([]);
  const [updatedBilansInpi, setUpdatedBilansInpi] = useState([]);

  async function handleResearch() {
    if (year) {
      try {
        setErrorAlert(false);
        setBilansInpi({});
        setLoading(true);
        setSuccessUpdateAlert(false);
        const bilans = await getUpdatedilanForYear(year);
        const bilansArray = transformToArrayProfiles(bilans);
        setBilansInpi(bilansArray);
      } catch (error) {
        console.log("error", error);
        setErrorAlert(true);
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setFormValid(false);
    }
  }

  const handleProcessRowUpdate = (newData) => {
    updatedBilansInpi.push(newData.slice(1));
    setSuccessUpdateAlert(false);
  };

  async function handleUpdateBilans() {
    if (updatedBilansInpi.length > 0) {
      try {
        setLoadingUpdate(true);
        setSuccessUpdateAlert(false);

        let header = bilansInpi[0];
        for (const item of bilansInpi) {
          if (Object.keys(item).length > Object.keys(header).length) {
            header = item;
          }
        }
        updatedBilansInpi.unshift(header);
        const jsonBilans = transformToJson(updatedBilansInpi);
        await editBilan(jsonBilans);
        setErrorUpdateAlert(false);
        setSuccessUpdateAlert(true);
        setUpdatedBilansInpi([]);
      } catch (error) {
        setSuccessUpdateAlert(false);
        setErrorUpdateAlert(true);
        setErrorUpdateMessage(error.message);
      } finally {
        setLoadingUpdate(false);
      }
    } else {
      setSuccessUpdateAlert(false);
      setErrorUpdateAlert(true);
      setErrorUpdateMessage(
        "Vous n'avez modifié aucune valeur dans le tableau."
      );
    }
  }

  return (
    <>
      <h1>Étape 2 : Vérification des bilans INPI</h1>
      <FormGroup
        sx={{
          rowGap: "2rem",
          marginBottom: "2rem",
        }}
      >
        <NumberInput
          label="Année d'étude"
          value={year}
          onChange={(year) => setYear(year)}
          minValue={2015}
          maxValue={maxYear}
          error={!formValid && !year}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "15%",
              marginRight: "1rem",
              minWidth: "110px",
            }}
            onClick={handleResearch}
          >
            Rechercher
          </Button>
          {loading && <CircularProgress size={25} />}
        </div>
        {errorAlert && (
          <ErrorAlert
            message={errorMessage}
            onClose={() => setErrorAlert(false)}
          />
        )}
      </FormGroup>
      {Object.keys(bilansInpi).length !== 0 && (
        <>
          <DfGrid
            data={bilansInpi}
            canEdit={true}
            editableCols={[
              "denomination",
              "ventes_de_marchandises",
              "production_vendue_biens",
              "production_vendue_services",
              "chiffres_affaires_nets",
              "production_stockee",
              "production_immobilisee",
              "subventions_exploitation",
              "achats_de_marchandises_y_compris_droits_de_douane",
              "variation_de_stock_marchandises",
              "achats_de_matieres_premieres_et_autres_approvisionnements",
              "variation_de_stock",
              "autres_achats_et_charges_externes",
              "impots_taxes_versements_assimiles",
              "salaires_et_traitements",
              "charges_sociales",
              "production",
              "ventes_BS",
              "achats_BS",
              "consommations_Intermediaires",
              "valeur_Ajoutée_Brute",
              "taux_De_Valeur_Ajoutee",
              "salaires_et_charges",
              "impots_et_subventions",
              "effectifs",
            ]}
            // colorRows="siren"
            onDataChange={handleProcessRowUpdate}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "15%",
                minWidth: "144px",
                marginRight: "1rem",
              }}
              onClick={handleUpdateBilans}
            >
              Mettre à jour
            </Button>
            {loadingUpdate && <CircularProgress size={25} />}
          </div>
          <div style={{ marginTop: "2rem" }}>
            {errorUpdateAlert && (
              <ErrorAlert
                message={errorUpdateMessage}
                onClose={() => setErrorUpdateAlert(false)}
              />
            )}

            {successUpdateAlert && (
              <SuccessAlert
                message="Les données ont été mises à jour avec succès."
                onClose={() => setSuccessUpdateAlert(false)}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default CheckBilanInpiTab;
