import styled from "styled-components";
import colors from "../../utils/style/colors";
import imageDark from "../../assets/page-not-found-dark.png";
import imageLight from "../../assets/page-not-found-light.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../utils/context";

const ErrorWrapper = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorTitle = styled.h1`
  font-weight: 600;
`;

const ErrorSubtitle = styled.p`
  font-weight: 300;
  text-align: center;
  margin-top: 3%;
`;

const Illustration = styled.img`
  max-width: 400px;
`;

function Error() {
  const navigate = useNavigate();

  const { theme } = useContext(ThemeContext);

  return (
    <ErrorWrapper>
      <ErrorTitle>Oups... Erreur 404</ErrorTitle>
      <Illustration src={theme === "light" ? imageLight : imageDark} />
      <a
        style={{
          fontSize: "14px",
          color: theme === "light" ? colors.corail : colors.darkCorail,
        }}
        href="https://www.freepik.com/author/pikisuperstar"
        target="_blank"
        rel="noreferrer"
      >
        Designed by pikisuperstar / Freepik
      </a>
      <ErrorSubtitle>
        Il semblerait que la page que vous recherchez a été déplacée, renommée,
        retirée, ou n'a peut-être jamais existé.
      </ErrorSubtitle>
      <Button onClick={() => navigate("/")} variant="contained">
        Retour à l'accueil
      </Button>
    </ErrorWrapper>
  );
}

export default Error;
