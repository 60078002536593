import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import DfGrid from "../../components/DfGrid";
import NumberInput from "../../components/NumberInput";
import FileUploader from "../../components/FileUploader";
import { sendProfilageData } from "../../services/Finance/FinanceService";

import { transformToJson } from "../../services/Finance/FinanceHelpers";
import { ErrorAlert, SuccessAlert } from "../../components/Alert";
import DownloadExcelButton from "../../components/DownloadExcelButton";

function ProfileLoadingTab() {
  const maxYear = parseInt(new Date().getFullYear() - 1);

  const [year, setYear] = useState(maxYear);
  const [force, setForce] = useState(false);

  const [formValid, setFormValid] = useState(true);
  const [excelData, setExcelData] = useState(null);
  const [errorMode, setErrorMode] = useState(false);

  const [loading, setLoading] = useState(false);

  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function handleSendData() {
    if (year && excelData) {
      try {
        setErrorMode(false);
        setLoading(true);
        setSuccessAlert(false);
        setErrorAlert(false);
        const dataLoaded = { profils: transformToJson(excelData[0]) };
        await sendProfilageData(dataLoaded, year, force);
        setSuccessAlert(true);
        setErrorAlert(false);
      } catch (error) {
        setErrorAlert(true);
        setSuccessAlert(false);
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMode(true);
      setFormValid(false);
    }
  }
  return (
    <div style={{ maxWidth: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Étape 1 : Chargement des profils</h1>
        <DownloadExcelButton
          path={"/assets/templates/Template_impacter_finance.xlsx"}
          filename={"impacter_finance.xlsx"}
        />
      </div>

      <FormGroup
        sx={{
          rowGap: "2rem",
        }}
      >
        <NumberInput
          label="Année d'étude"
          value={year}
          onChange={(year) => setYear(year)}
          minValue={2015}
          maxValue={maxYear}
          error={!formValid && !year}
        />
        <FileUploader onDataUpload={setExcelData} error={errorMode} />
        {excelData && (
          <div
            style={{
              width: "100%",
            }}
          >
            <DfGrid data={excelData[0]} colorRows="SIREN_GROUPE" />
          </div>
        )}
        <FormControlLabel
          control={<Checkbox />}
          label="Écraser les données existantes"
          checked={force}
          onChange={(event) => setForce(event.target.checked)}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "15%",
              minWidth: "110px",
              marginRight: "15px",
            }}
            onClick={handleSendData}
          >
            Envoyer
          </Button>
          {loading && <CircularProgress size={25} />}
        </div>
        {successAlert && (
          <SuccessAlert
            message="Les données ont été téléchargées avec succès."
            onClose={() => setSuccessAlert(false)}
          />
        )}
        {errorAlert && (
          <ErrorAlert
            message={errorMessage}
            onClose={() => setErrorAlert(false)}
          />
        )}
      </FormGroup>
    </div>
  );
}

export default ProfileLoadingTab;
