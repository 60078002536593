import { useState } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PasswordInput({ label, value, onChange, error, errorMessage }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <FormControl>
      <TextField
        error={error ? true : false}
        helperText={error ? errorMessage : null}
        id={label}
        label={label}
        type={showPassword ? "text" : "password"}
        variant="outlined"
        value={value}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

PasswordInput.defaultProps = {
  label: "",
  errorMessage: "Veuillez entrer une valeur",
};

export default PasswordInput;
