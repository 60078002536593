import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import colors from "../../utils/style/colors";
import { useContext, useRef, useEffect, useState } from "react";
import { ThemeContext } from "../../utils/context";
import Xarrow from "react-xarrows";
import ImpactSchemaLegend from "../ImpactSchemaLegend";
import ImpactSchemaCard from "../ImpactSchemaCard";

const ImpactSchema = ({ profile, unit }) => {
  const minHeight = 190;
  const maxHeight = 45;
  const minWidth = 190;
  const maxWidth = 45;
  const [maxHeightPx, setMaxHeightPx] = useState(0);
  const [maxWidthPx, setMaxWidthPx] = useState(0);

  useEffect(() => {
    const vhToPx = (vh) => {
      const height = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );

      return (vh * height) / 100;
    };

    const vwToPx = (vw) => {
      const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );

      return (vw * width) / 100;
    };

    const maxHPx = vhToPx(maxHeight);
    const maxWPx = vwToPx(maxWidth);
    setMaxHeightPx(maxHPx);
    setMaxWidthPx(maxWPx);

    const handleResize = () => {
      setMaxHeightPx(vhToPx(maxHeight));
      setMaxWidthPx(vwToPx(maxWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { theme } = useContext(ThemeContext);

  const threeImpactsEmploi = [
    profile.direct_emploi,
    profile.indirect_emploi,
    profile.induit_emploi,
  ];

  const minEmploi = Math.max(Math.min(...threeImpactsEmploi), 1);

  const calculateDimensions = (
    emploi,
    minWidth,
    minHeight,
    maxWidthPx,
    maxHeightPx
  ) => {
    const area = (minWidth * minHeight * emploi) / minEmploi;

    let height = Math.sqrt(area);
    let width = Math.sqrt(area);

    if (height > maxHeightPx) {
      height = maxHeightPx;
      if (area / height < maxWidthPx) width = area / height;
      else width = maxWidthPx;
    }

    if (width > maxWidthPx) {
      width = maxWidthPx;
      if (area / width < maxHeightPx) height = area / width;
      else height = maxHeightPx;
    }

    return { width, height };
  };

  const dimensionsDirect = calculateDimensions(
    Math.max(profile.direct_emploi, 1),
    minWidth,
    minHeight,
    maxWidthPx,
    maxHeightPx
  );
  const dimensionsIndirect = calculateDimensions(
    Math.max(profile.indirect_emploi, 1),
    minWidth,
    minHeight,
    maxWidthPx,
    maxHeightPx
  );
  const dimensionsInduit = calculateDimensions(
    Math.max(profile.induit_emploi, 1),
    minWidth,
    minHeight,
    maxWidthPx,
    maxHeightPx
  );

  const directBox = useRef(null);
  const indirectBox = useRef(null);
  const induitBox = useRef(null);
  return (
    <>
      {maxWidthPx > 0 && maxHeightPx > 0 && (
        <>
          <div style={{ marginTop: "2rem" }}>
            <h3>
              Représentation schématisée des impacts directs, indirects et
              induits du groupe {profile.denomination_groupe}
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <ImpactSchemaLegend unit={unit} />
          </div>
          <Grid
            container
            spacing={3}
            direction={"row"}
            style={{ width: "100%", marginTop: 0 }}
          >
            <Grid
              item
              xs={6}
              display="flex"
              justifyContent="center"
              style={{ width: "100%" }}
            >
              <ImpactSchemaCard
                title="Impacts directs"
                backgroundColor={
                  theme === "light" ? colors.ciel : colors.darkCiel
                }
                emploi={profile.direct_emploi}
                minEmploi={minEmploi}
                VAB={profile.direct_VAB}
                production={profile.direct_production}
                unit={unit}
                forwardRef={directBox}
                height={dimensionsDirect.height}
                width={dimensionsDirect.width}
                minWidth={minWidth}
                minHeight={minHeight}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
              />
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <Grid container spacing={5} direction={"column"}>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  style={{ width: "100%" }}
                  justifyContent="center"
                >
                  <ImpactSchemaCard
                    title="Impacts indirects"
                    backgroundColor={"rgb(229, 90, 41,0.6)"}
                    emploi={profile.indirect_emploi}
                    minEmploi={minEmploi}
                    VAB={profile.indirect_VAB}
                    production={profile.indirect_production}
                    unit={unit}
                    forwardRef={indirectBox}
                    height={dimensionsIndirect.height}
                    width={dimensionsIndirect.width}
                    minWidth={minWidth}
                    minHeight={minHeight}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  style={{ width: "100%" }}
                  justifyContent="center"
                >
                  <ImpactSchemaCard
                    title="Impacts induits"
                    backgroundColor={
                      theme === "light" ? colors.pollen : colors.darkPollen
                    }
                    emploi={profile.induit_emploi}
                    minEmploi={minEmploi}
                    VAB={profile.induit_VAB}
                    production={profile.induit_production}
                    unit={unit}
                    forwardRef={induitBox}
                    height={dimensionsInduit.height}
                    width={dimensionsInduit.width}
                    minWidth={minWidth}
                    minHeight={minHeight}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Xarrow
              start={directBox}
              end={indirectBox}
              headSize={2.2}
              curveness={0}
              strokeWidth={15}
              color={theme === "light" ? "black" : "white"}
              animateDrawing
              endAnchor={{
                position: "left",
                offset: { x: 13, y: -dimensionsIndirect.height / 2 + 30 },
              }}
              startAnchor={{
                position: "right",
                offset: { x: 0, y: -dimensionsDirect.height / 2 + 30 },
              }}
            />
            <Xarrow
              start={indirectBox}
              end={induitBox}
              headSize={2.2}
              strokeWidth={15}
              color={theme === "light" ? "black" : "white"}
              curveness={0}
              animateDrawing
              endAnchor={{
                position: "top",
                offset: { y: 13 },
              }}
            />
            <Xarrow
              start={directBox}
              end={induitBox}
              headSize={2.2}
              strokeWidth={15}
              color={theme === "light" ? "black" : "white"}
              path="grid"
              animateDrawing
              endAnchor={{
                position: "left",
                offset: { x: 13, y: 0 },
              }}
              startAnchor={{
                position:
                  dimensionsDirect.height <= dimensionsIndirect.height
                    ? "bottom"
                    : "right",
                offset: {
                  x: 0,
                  y:
                    dimensionsDirect.height <= dimensionsIndirect.height
                      ? 0
                      : dimensionsDirect.height / 2 - 30,
                },
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
};
ImpactSchema.propTypes = {
  profile: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
};

export default ImpactSchema;
