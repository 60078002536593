import { useContext, useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import image from "../../assets/pexels-alexey-komissarov-9021385.jpg";
import logoLight from "../../assets/LOGO+BASELINE_Vertigolab.svg";
import logoDark from "../../assets/LOGO+BASELINE_BLANC_Vertigolab.svg";
import Input from "../../components/Input";
import PasswordInput from "../../components/PasswordInput";
import { ThemeContext } from "../../utils/context";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../provider/authProvider";
import { ErrorAlert } from "../../components/Alert";

function LoginPage() {
  const { theme } = useContext(ThemeContext);
  const logoPath = theme === "light" ? logoLight : logoDark;

  const { signIn, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/", { replace: true });
    }
  }, [token, navigate]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formValid, setFormValid] = useState(true);
  const [isCredentialOk, setIsCredentialOk] = useState(true);

  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 900px)" });

  async function handleLogin(event) {
    event.preventDefault();
    if (!username || !password) {
      setErrorAlert(false);
      setFormValid(false);
    } else {
      try {
        setErrorAlert(false);
        await signIn(username, password);
      } catch (error) {
        let errorMessage = error.message.replace(/^Error: /, "");

        if (
          errorMessage ===
          "Les informations d'identification fournies sont incorrectes. Veuillez vérifier votre nom d'utilisateur et votre mot de passe et réessayer."
        ) {
          setIsCredentialOk(false);
        } else {
          setErrorAlert(true);
          setErrorMessage(errorMessage);
        }
      }
    }
  }

  function handleInputChange() {
    setIsCredentialOk(true);
    setFormValid(true);
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ overflow: "hidden", height: "100vh" }}
    >
      {isDesktopOrLaptop && (
        <Grid item md={4} lg={6} xl={7}>
          <img
            src={image}
            alt=""
            style={{
              width: "100%",
              height: "100vh",
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={6}
        xl={5}
        style={{
          marginTop: -8,
          height: "100%",
          alignContent: "center",
          boxShadow: "-5px 0 15px 15px rgba(0, 0, 0, 0.2)",
          zIndex: 1,
        }}
      >
        <form onSubmit={handleLogin}>
          <FormGroup
            sx={{
              rowGap: "2rem",
              paddingX: isDesktopOrLaptop ? "5rem" : "2rem",
              marginBottom: "1rem",
            }}
          >
            <img
              src={logoPath}
              alt=""
              height="100vh"
              style={{ margin: "auto" }}
            />
            <h1
              style={{
                textAlign: "center",
                margin: 0,
                fontSize: isDesktopOrLaptop ? "2rem" : "1.5rem",
              }}
            >
              Connexion à ImpacTer
            </h1>
            <Input
              label="Identifiant"
              value={username}
              onChange={(username) => {
                setUsername(username);
                handleInputChange();
              }}
              error={(!formValid && !username) || !isCredentialOk}
              errorMessage={
                !username
                  ? "Veuillez saisir un identifiant"
                  : "Identifiant ou mot de passe incorrect"
              }
            />
            <PasswordInput
              label="Mot de passe"
              value={password}
              onChange={(pw) => {
                setPassword(pw);
                handleInputChange();
              }}
              error={(!formValid && !password) || !isCredentialOk}
              errorMessage={
                !password
                  ? "Veuillez saisir un mot de passe"
                  : "Identifiant ou mot de passe incorrect"
              }
            />

            <Button variant="contained" size="large" type="submit">
              Se connecter
            </Button>
            {errorAlert && (
              <ErrorAlert
                message={errorMessage}
                onClose={() => setErrorAlert(false)}
              />
            )}
          </FormGroup>
        </form>

        <Footer />
      </Grid>
    </Grid>
  );
}

export default LoginPage;
