import React, { useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import colors from "../../utils/style/colors";
import PropTypes from "prop-types";
import { ThemeContext } from "../../utils/context";
import { useSpring, animated } from "react-spring";
import { formatNumberWithComaAndSpace } from "../../services/Finance/FinanceHelpers";

const Number = React.memo(({ n, decimalPlaces }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 35, friction: 15 },
  });
  return (
    <animated.div>
      {number.to((n) => formatNumberWithComaAndSpace(n.toFixed(decimalPlaces)))}
    </animated.div>
  );
});

function KeyFigure({ value, unit, description, background, decimalPlaces }) {
  const { theme } = useContext(ThemeContext);

  const memoizedNumber = useMemo(() => {
    return <Number n={value} decimalPlaces={decimalPlaces} />;
  }, [value, decimalPlaces]);

  return (
    <Box
      display="flex"
      alignItems="center"
      backgroundColor={background ? "background.main" : null}
      borderRadius={"14px"}
      paddingX={background ? "5%" : null}
      width="fit-content"
      margin="auto"
    >
      <div
        style={{
          fontSize: "3.75rem",
          fontWeight: "bold",
          color: "transparent",
          margin: 0,
          WebkitTextStroke: `1.5px ${
            theme === "light" ? colors.corail : colors.darkCorail
          }`,
          textStroke: `1.5px ${colors.corail}`,
        }}
      >
        {memoizedNumber}
      </div>

      <Box marginLeft={3}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "1.4rem",
            marginTop: -5,
            marginBottom: 0,
          }}
        >
          {unit}
        </p>
        <p style={{ fontWeight: "300", marginBottom: 0, marginTop: -6 }}>
          {description}
        </p>
      </Box>
    </Box>
  );
}

KeyFigure.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  unit: PropTypes.string,
  description: PropTypes.string,
  background: PropTypes.bool,
  decimalPlaces: PropTypes.number,
};

export default KeyFigure;
