import { useContext } from "react";
import { ThemeContext } from "../../utils/context";
import colors from "../../utils/style/colors";
import IconAndValue from "../IconAndValue";
import { PiCoins } from "react-icons/pi";
import { PiChartLineUp } from "react-icons/pi";
import { FaPeopleGroup } from "react-icons/fa6";
import { ImArrowRight } from "react-icons/im";

const ImpactSchemaLegend = ({ unit }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      style={{
        border: "2px dashed",
        borderColor: `${theme === "light" ? colors.foret : colors.darkForet}`,
        borderRadius: "10px",
        padding: "2%",
        width: "fit-content",
        marginLeft: "1rem",
        marginTop: "1rem",
      }}
    >
      <strong
        style={{
          color: `${theme === "light" ? colors.foret : colors.darkForet}`,
        }}
      >
        Légende
      </strong>
      <IconAndValue IconName={PiCoins} text={`Production en ${unit}`} />
      <IconAndValue
        IconName={PiChartLineUp}
        text={`Valeur ajoutée en ${unit}`}
      />
      <IconAndValue
        IconName={FaPeopleGroup}
        text={"Emploi en ETP (équivalent temps plein)"}
      />
      <IconAndValue IconName={ImArrowRight} text={"Entraîne"} />
    </div>
  );
};

export default ImpactSchemaLegend;
