function translateTerritoryType(data) {
  switch (data) {
    case "région":
      return "region";
    case "département":
      return "departement";
    case "commune":
      return "commune";
    case "communauté de commune":
      return "co-commune";
    default:
      throw new Error("Error this type does not exist");
  }
}

function translateEstimateType(data) {
  switch (data) {
    case "salarié":
      return "salarie";
    case "non salarié":
      return "non_salarie";
    case "totaux":
      return "totaux";
    default:
      throw new Error("Error this type of job does not exist");
  }
}

function translateMultiplierType(data) {
  switch (data) {
    case "emploi":
      return "emploi";
    case "emploi type 1":
      return "emploi_type_1";
    case "emploi type 2":
      return "emploi_type_2";
    case "production":
      return "prod";
    case "production type 1":
      return "prod_type_1";
    case "production type 2":
      return "prod_type_2";
    case "VAB":
      return "vab";
    case "VAB type 1":
      return "vab_type_1";
    case "VAB type 2":
      return "vab_type_2";
    default:
      throw new Error("Error this type of multiplier does not exist");
  }
}

function transformToArrayJob(data, typeOfJob) {
  const result = [];

  result.push(["Code NA64", typeOfJob]);

  data.forEach((obj) => {
    const row = [obj.code_na64, obj.value];
    result.push(row);
  });

  return result;
}

function transformToArrayMultipliers(data) {
  const result = [];

  result.push([
    "Code NA64",
    "Emploi",
    "Emploi type 1",
    // "Emploi type 2",
    // "Production",
    // "Production type 1",
    // "Production type 2",
    "VAB",
    "VAB type 1",
    // "VAB type 2",
  ]);
  data.emploi.forEach((item) => {
    const code_na64 = item.code_na64;
    const emploiType1 =
      data.emploi_type_1.find((i) => i.code_na64 === code_na64)?.value || 0;
    const emploi =
      data.emploi.find((i) => i.code_na64 === code_na64)?.value || 0;
    const vab = data.vab.find((i) => i.code_na64 === code_na64)?.value || 0;
    const vabType1 =
      data.vab_type_1.find((i) => i.code_na64 === code_na64)?.value || 0;
    const row = [
      code_na64,
      emploi * 1000000, //On parle en emploi/M€
      emploiType1 * 1000000,
      vab,
      vabType1,
    ];
    result.push(row);
  });

  return result;
}

export {
  translateTerritoryType,
  translateEstimateType,
  translateMultiplierType,
  transformToArrayMultipliers,
  transformToArrayJob,
};
