import DfGrid from "../../components/DfGrid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { useState, useEffect, useCallback, useMemo } from "react";
import {
  computeImpacter,
  getAllExpensesForSirenForYear,
  getProfileForYearAndSiren,
  updateImpacter,
  deleteExpenses,
} from "../../services/Classique/ClassiqueService";
import {
  transformToArrayProfiles,
  transformToJson,
} from "../../services/Finance/FinanceHelpers";
import { ErrorAlert, WarningAlert, SuccessAlert } from "../../components/Alert";
import ClassicForm from "../../components/ClassicForm";

function ClassicCompleteInfoTab() {
  const maxYear = parseInt(new Date().getFullYear() - 2);
  const [formValid, setFormValid] = useState(true);
  const [year, setYear] = useState(maxYear);
  const [sirenGroup, setSirenGroup] = useState("");
  const [territory, setTerritory] = useState("France");

  const [loading, setLoading] = useState(false);
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);
  const [loadingUpdateExpenses, setLoadingUpdateExpenses] = useState(false);
  const [loadingCompute, setLoadingCompute] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorAlertCompute, setErrorAlertCompute] = useState(false);
  const [errorMessageCompute, setErrorMessageCompute] = useState("");
  const [errorAlertUpdateProfile, setErrorAlertUpdateProfile] = useState(false);
  const [errorMessageUpdateProfile, setErrorMessageUpdateProfile] =
    useState("");
  const [successAlertUpdateProfile, setSuccessAlertUpdateProfile] =
    useState(false);
  const [errorAlertUpdateExpenses, setErrorAlertUpdateExpenses] =
    useState(false);
  const [errorMessageUpdateExpenses, setErrorMessageUpdateExpenses] =
    useState("");
  const [successAlertUpdateExpenses, setSuccessAlertUpdateExpenses] =
    useState(false);
  const [successAlertCompute, setSuccessAlertCompute] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [emptyFieldsProfile, setEmptyFieldsProfile] = useState([]);

  const [expensesData, setExpensesData] = useState([]);
  const [incorrectNAF, setIncorrectNAF] = useState([]);
  const [updatedExpenses, setUpdatedExpenses] = useState([]);

  const [rowsToDelete, setRowsToDelete] = useState([]);

  async function handleUpdateProfile() {
    if (emptyFieldsProfile.length === 0 && profileData.length !== 0) {
      try {
        setLoadingUpdateProfile(true);
        setErrorAlertUpdateProfile(false);
        setSuccessAlertUpdateProfile(false);
        const profileDataJson = transformToJson(profileData);
        await updateImpacter(profileDataJson, "update-impacter");
        setSuccessAlertUpdateProfile(true);
      } catch (error) {
        setErrorAlertUpdateProfile(true);
        setErrorMessageUpdateProfile(error.message);
      } finally {
        setLoadingUpdateProfile(false);
      }
    }
  }

  async function handleUpdateExpenses() {
    if (
      incorrectNAF.length === 0 &&
      (updatedExpenses.length !== 0 || rowsToDelete.length !== 0)
    ) {
      try {
        setLoadingUpdateExpenses(true);
        setErrorAlertUpdateExpenses(false);
        setSuccessAlertUpdateExpenses(false);
        if (updatedExpenses.length > 0) {
          updatedExpenses.unshift([
            "id",
            "annee",
            "siren_acheteur",
            "siret_fournisseur",
            "naf_fournisseur",
            "code_postal",
            "pays",
            "montant",
            "type",
          ]);
          const updatedExpensesJson = transformToJson(updatedExpenses);

          await updateImpacter(updatedExpensesJson, "update-expenses");
        }
        if (rowsToDelete.length > 0) {
          const rowsToDeleteJson = { ids: rowsToDelete };
          await deleteExpenses(rowsToDeleteJson);
          setRowsToDelete([]);
        }
        setExpensesData([]);
        const expenses = await getAllExpensesForSirenForYear(year, sirenGroup);
        const expensesArray = transformToArrayProfiles(expenses);

        setExpensesData(expensesArray);

        setUpdatedExpenses([]);
        setSuccessAlertUpdateExpenses(true);
      } catch (error) {
        setErrorAlertUpdateExpenses(true);
        setErrorMessageUpdateExpenses(error.message);
      } finally {
        setLoadingUpdateExpenses(false);
      }
    }
  }

  async function handleResearch() {
    if (year && sirenGroup && territory) {
      try {
        setErrorAlert(false);
        setProfileData({});
        setSuccessAlertUpdateProfile(false);
        setErrorAlertUpdateProfile(false);
        setSuccessAlertUpdateExpenses(false);
        setErrorAlertUpdateExpenses(false);
        setSuccessAlertCompute(false);
        setErrorAlertCompute(false);
        setLoading(true);
        const profile = await getProfileForYearAndSiren(year, sirenGroup);
        const profileArray = transformToArrayProfiles(profile);
        setProfileData(profileArray);
        const expenses = await getAllExpensesForSirenForYear(year, sirenGroup);
        const expensesArray = transformToArrayProfiles(expenses);
        setExpensesData(expensesArray);
      } catch (err) {
        setErrorAlert(true);
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      setFormValid(false);
    }
  }

  async function handleComputeImpacter() {
    try {
      setLoadingCompute(true);
      setErrorAlertCompute(false);
      setSuccessAlertCompute(false);
      await computeImpacter(sirenGroup, year, territory);
      setSuccessAlertCompute(true);
    } catch (error) {
      setErrorAlertCompute(true);
      setErrorMessageCompute(error.message);
    } finally {
      setLoadingCompute(false);
    }
  }

  const columnsProfile = useMemo(
    () => [
      "nom_entite",
      "annee",
      "siren",
      "pays",
      "code_postal",
      "production",
      "CI",
      "effectif",
      "remuneration",
    ],
    []
  );

  const columnsExpenses = useMemo(
    () => [
      "id",
      "siret_fournisseur",
      "naf_fournisseur",
      "code_postal",
      "pays",
      "montant",
      "type",
    ],
    []
  );
  const checkEmptyFields = useCallback(
    (data) => {
      const emptyFields = [];
      const columns = data[0];
      for (let i = 1; i < data.length; i++) {
        const row = data[i];
        for (let j = 0; j < columns.length; j++) {
          const col = columns[j];
          if (columnsProfile.includes(col) && !row[j]) {
            emptyFields.push(col);
          }
        }
      }
      return [...new Set(emptyFields)];
    },
    [columnsProfile]
  );

  const checkEmptyNAF = useCallback((data) => {
    const jsonData = transformToJson(data);
    const incorrectNAFs = jsonData
      .filter((item) => !item.naf_fournisseur)
      .map((item) => item.id);
    return incorrectNAFs;
  }, []);

  useEffect(() => {
    if (profileData.length > 0) {
      const fields = checkEmptyFields(profileData);
      setEmptyFieldsProfile(fields);
    }
    if (expensesData.length > 0) {
      const incorrectNAF = checkEmptyNAF(expensesData);
      setIncorrectNAF(incorrectNAF);
    }
  }, [profileData, checkEmptyFields, expensesData, checkEmptyNAF]);

  const handleCellValueChanged = (event) => {
    const updatedRow = event.slice(1);

    const updatedData = [...profileData];
    updatedData[1] = updatedRow;
    setProfileData(updatedData);

    const fields = checkEmptyFields(updatedData);
    setEmptyFieldsProfile(fields);
  };

  const handleExpenseValueChanged = (event, gridData) => {
    const gridDataArray = transformToArrayProfiles(gridData);

    const eventId = event[0];
    const updatedExpensesData = gridDataArray.map((row) => {
      if (row[0] === eventId) {
        return event;
      }
      return row;
    });

    updatedExpenses.push(event);
    setExpensesData(updatedExpensesData);
    const incorrectNAFs = checkEmptyNAF(updatedExpensesData);
    setIncorrectNAF(incorrectNAFs);
  };

  return (
    <>
      <h1>Étape 2 : Complétion des informations manquantes</h1>
      <ClassicForm
        year={year}
        setYear={setYear}
        maxYear={maxYear}
        sirenGroup={sirenGroup}
        setSirenGroup={setSirenGroup}
        territory={territory}
        setTerritory={setTerritory}
        formValid={formValid}
        handleResearch={handleResearch}
        loading={loading}
        errorAlert={errorAlert}
        errorMessage={errorMessage}
        setErrorAlert={setErrorAlert}
      />
      {Object.keys(profileData).length !== 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <h2>Profil du groupe étudié</h2>
          <DfGrid
            data={profileData}
            canFilter={false}
            displayPagination={false}
            rowSize={40}
            columnsToDisplay={columnsProfile}
            canEdit={true}
            editableCols={columnsProfile}
            onDataChange={handleCellValueChanged}
          />
        </div>
      )}
      {emptyFieldsProfile.length > 0 && (
        <WarningAlert
          message={`Les champs suivants sont vides : ${emptyFieldsProfile.join(
            ", "
          )}. Veuillez les remplir et mettre à jour pour pouvoir effectuer les calculs.`}
        />
      )}
      {Object.keys(profileData).length !== 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "2rem",
          }}
        >
          <Button
            variant="outlined"
            disabled={emptyFieldsProfile.length > 0}
            sx={{
              width: "15%",
              minWidth: "144px",
              marginRight: "1rem",
            }}
            onClick={handleUpdateProfile}
          >
            Mettre à jour
          </Button>
          {loadingUpdateProfile && <CircularProgress size={25} />}
          {errorAlertUpdateProfile && (
            <ErrorAlert
              message={errorMessageUpdateProfile}
              onClose={() => setErrorAlertUpdateProfile(false)}
            />
          )}
          {successAlertUpdateProfile && (
            <SuccessAlert
              message="Données modifiées avec succès !"
              onClose={() => setSuccessAlertUpdateProfile(false)}
            />
          )}
        </div>
      )}

      {Object.keys(expensesData).length !== 0 && (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <h2>Dépenses associées</h2>
            <DfGrid
              data={expensesData}
              columnsToDisplay={columnsExpenses}
              canEdit={true}
              editableCols={columnsExpenses.filter((col) => col !== "id")}
              onDataChange={handleExpenseValueChanged}
              canDelete={true}
              colDelete={"id"}
              setRowsToDelete={setRowsToDelete}
            />
          </div>
          {incorrectNAF.length > 0 && (
            <WarningAlert
              message={`Les codes NAF des lignes suivantes doivent être renseignés : ${incorrectNAF.join(
                ", "
              )}.`}
            />
          )}
          {}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Button
              variant="outlined"
              disabled={
                incorrectNAF.length !== 0 ||
                (updatedExpenses.length === 0 && rowsToDelete.length === 0)
              }
              sx={{
                width: "15%",
                minWidth: "144px",
                marginRight: "1rem",
              }}
              onClick={handleUpdateExpenses}
            >
              Mettre à jour
            </Button>
            {loadingUpdateExpenses && <CircularProgress size={25} />}
          </div>
          {errorAlertUpdateExpenses && (
            <div style={{ marginBottom: "2rem" }}>
              <ErrorAlert
                message={errorMessageUpdateExpenses}
                onClose={() => setErrorAlertUpdateExpenses(false)}
              />
            </div>
          )}
          {successAlertUpdateExpenses && (
            <div style={{ marginBottom: "2rem" }}>
              <SuccessAlert
                message="Données des dépenses modifiées avec succès !"
                onClose={() => setSuccessAlertUpdateExpenses(false)}
              />
            </div>
          )}
        </>
      )}
      {Object.keys(profileData).length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Button
              variant="contained"
              disabled={
                incorrectNAF.length > 0 || emptyFieldsProfile.length > 0
              }
              sx={{
                width: "22%",
                minWidth: "201px",
                marginRight: "1rem",
              }}
              onClick={handleComputeImpacter}
            >
              Calculer les impacts
            </Button>
            {loadingCompute && <CircularProgress size={25} />}
          </div>
          {errorAlertCompute && (
            <ErrorAlert
              message={errorMessageCompute}
              onClose={() => setErrorAlertCompute(false)}
            />
          )}
          {successAlertCompute && (
            <SuccessAlert
              message="Les impacts ont été calculés avec succès !"
              onClose={() => setSuccessAlertCompute(false)}
            />
          )}
        </>
      )}
    </>
  );
}

export default ClassicCompleteInfoTab;
