import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
const LogoutDialog = ({
  confirmLogoutOpen,
  setConfirmLogoutOpen,
  handleConfirmLogout,
  theme,
}) => {
  return (
    <Dialog
      open={confirmLogoutOpen}
      onClose={() => setConfirmLogoutOpen(false)}
    >
      <DialogTitle>Êtes-vous sûr·e de vouloir vous déconnecter ?</DialogTitle>
      <DialogContent>
        La déconnexion mettra fin à votre session actuelle.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setConfirmLogoutOpen(false)}
          variant={theme === "light" ? "text" : "outlined"}
          color={theme === "light" ? "primary" : "text"}
        >
          Annuler
        </Button>
        <Button
          onClick={handleConfirmLogout}
          color="danger"
          variant={theme === "light" ? "outlined" : "contained"}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
