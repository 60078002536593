import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

const IconMenuDrawer = ({ toggleDrawer }) => {
  return (
    <IconButton
      onClick={toggleDrawer(true)}
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ display: { md: "none" } }}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default IconMenuDrawer;
