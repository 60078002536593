import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import NumberInput from "../../components/NumberInput";
import { getProfileGroup } from "../../services/Finance/FinanceService";
import DfGrid from "../../components/DfGrid";
import { transformToArrayProfiles } from "../../services/Finance/FinanceHelpers";
import { downloadXLSX } from "../../services/allHelpers";
import { ErrorAlert, SuccessAlert } from "../../components/Alert";

function ProfileGroupTab() {
  const maxYear = parseInt(new Date().getFullYear() - 1);

  const [year, setYear] = useState(maxYear);

  const [formValid, setFormValid] = useState(true);

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [dataProfileGroup, setDataProfileGroup] = useState({});

  async function handleResearch() {
    if (year) {
      const strYear = year.toString();
      try {
        setSuccessAlert(false);
        setLoading(true);
        setDataProfileGroup({});
        const response = await getProfileGroup(strYear);
        const dataTransformed = transformToArrayProfiles(response);
        setDataProfileGroup(dataTransformed);
        setErrorAlert(false);
      } catch (error) {
        setErrorAlert(true);
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setFormValid(false);
    }
  }

  const handleDownload = (data, setterLoading, setterAlert) => {
    setterAlert(false);
    setterLoading(true);
    downloadXLSX(data, "profil", `profil_groupe_${year}.xlsx`);
    setterLoading(false);
    setterAlert(true);
  };

  return (
    <>
      <h1>Résultats globaux</h1>
      <FormGroup
        sx={{
          rowGap: "2rem",
        }}
      >
        <NumberInput
          label="Année d'étude"
          value={year}
          onChange={(year) => setYear(year)}
          minValue={2015}
          maxValue={maxYear}
          error={!formValid && !year}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "15%",
              marginRight: "1rem",
              minWidth: "110px",
            }}
            onClick={handleResearch}
          >
            Rechercher
          </Button>
          {loading && <CircularProgress size={25} />}
          {Object.keys(dataProfileGroup).length !== 0 && (
            <>
              <Button
                sx={{
                  width: "15%",
                  minWidth: "110px",
                  marginRight: "1rem",
                }}
                onClick={() =>
                  handleDownload(
                    dataProfileGroup,
                    setDownloadLoading,
                    setSuccessAlert
                  )
                }
                startIcon={<FileDownloadRoundedIcon />}
              >
                Télécharger
              </Button>
              {downloadLoading && <CircularProgress size={25} />}
            </>
          )}
        </div>
        {successAlert && (
          <SuccessAlert
            message="Les données ont été téléchargées avec succès."
            onClose={() => setSuccessAlert(false)}
          />
        )}

        {Object.keys(dataProfileGroup).length !== 0 && (
          <div
            style={{
              width: "100%",
            }}
          >
            <DfGrid data={dataProfileGroup} colorRows="Code NA64" />
          </div>
        )}

        {errorAlert && (
          <ErrorAlert
            message={errorMessage}
            onClose={() => setErrorAlert(false)}
          />
        )}
      </FormGroup>
    </>
  );
}

export default ProfileGroupTab;
