import React, { useState, createContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import colors from "../style/colors";
import { useMediaQuery } from "@mui/material";

export const ThemeContext = createContext();

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: colors.foret,
          },
          secondary: {
            main: colors.corail,
          },
          greyButton: {
            main: "#707b7d",
          },
          neutral: {
            main: "#fff",
          },
          text: {
            primary: "#101010",
          },
          grey: {
            main: "#c4c4c4",
            light: "#f1f1f1",
          },
          danger: {
            main: "#d63b3b",
          },
          background: {
            main: "#F9F9F9",
          },
          pollen: {
            main: colors.pollen,
            light: colors.pollenLight,
          },
        }
      : {
          primary: {
            main: colors.darkForet,
          },
          secondary: {
            main: colors.darkCorail,
          },
          text: {
            primary: "#fff",
            main: "#fff",
          },
          greyButton: {
            main: "#cbcdca",
          },
          neutral: {
            main: "#101010",
          },
          grey: {
            main: "#404040",
            light: "#262626",
          },
          danger: {
            main: "#f34336",
          },
          background: {
            main: "#121212",
          },
          pollen: {
            main: colors.darkPollen,
            light: colors.darkPollenLight,
          },
        }),
  },
});

export const ThemeProviderVL = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");
  const defaultTheme = prefersDarkMode ? "light" : "dark";
  const [theme, setTheme] = useState(defaultTheme);

  const toggleTheme = () => {
    setTheme((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const themeMUI = createTheme(getDesignTokens(theme));

  return (
    <ThemeProvider theme={themeMUI}>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};
