import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import ArticleResourceCard from "../ArticleResourceCard";
import articlesResources from "../../data/articlesResources";

function MultipliersTooltip() {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "45vw",
      backgroundColor: theme.palette.neutral.main,
      color: theme.palette.text.primary,
      padding: "1% 3%",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      fontSize: "14px",
      borderRadius: "10px",
    },
  }));

  return (
    <CustomTooltip
      color="secondary"
      placement="right-end"
      title={<ArticleResourceCard article={articlesResources[0]} />}
    >
      <IconButton>
        <HelpOutlineOutlinedIcon />
      </IconButton>
    </CustomTooltip>
  );
}

export default MultipliersTooltip;
