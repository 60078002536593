import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import logoLight from "../../assets/LOGO_Vertigolab.svg";
import logoDark from "../../assets/LOGO_Vertigolab_blanc.svg";

function Logo({ theme }) {
  const logoPath = theme === "light" ? logoLight : logoDark;
  return (
    <IconButton component={Link} to="/" color="inherit" aria-label="home">
      <img src={logoPath} alt="Logo" style={{ width: "auto", height: 70 }} />
    </IconButton>
  );
}

export default Logo;
