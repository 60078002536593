import Button from "@mui/material/Button";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";

function DownloadExcelButton({ path, filename }) {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = path;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      sx={{
        width: "20%",
        // minWidth: "110px",
      }}
      onClick={handleDownload}
      startIcon={<FileDownloadRoundedIcon />}
    >
      Télécharger le modèle
    </Button>
  );
}
export default DownloadExcelButton;
