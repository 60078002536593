import React, { useState } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

function NumberInput({ label, value, onChange, minValue, maxValue, error }) {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event) => {
    let newValue = parseInt(event.target.value);
    onChange(newValue);
    setInputValue(newValue);
  };

  const handleBlur = () => {
    let newValue = parseInt(inputValue);
    if (!newValue) {
      newValue = maxValue;
    } else if (newValue < minValue) {
      newValue = minValue;
    } else if (newValue > maxValue) {
      newValue = maxValue;
    }
    onChange(newValue);
    setInputValue(newValue);
  };

  const handleKeyDown = (event) => {
    // Empêcher la saisie des caractères interdits
    if (
      event.key === "e" ||
      event.key === "+" ||
      event.key === "-" ||
      event.key === "," ||
      event.key === "."
    ) {
      event.preventDefault();
    }
  };

  return (
    <FormControl>
      <TextField
        id="outlined-basic"
        label={label}
        type="number"
        variant="outlined"
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          min: minValue,
          max: maxValue,
        }}
        onKeyDown={handleKeyDown}
        error={error ? true : false}
        helperText={error ? "Veuillez entrer une année" : null}
      />
    </FormControl>
  );
}

NumberInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

NumberInput.defaultProps = {
  label: "",
};

export default NumberInput;
