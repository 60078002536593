import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

function Input({ label, value, onChange, error, errorMessage }) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <TextField
        error={error ? true : false}
        helperText={error ? errorMessage : null}
        id={label}
        label={label}
        variant="outlined"
        onChange={handleChange}
      />
    </FormControl>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

Input.defaultProps = {
  label: "",
  errorMessage: "Veuillez entrer une valeur",
};

export default Input;
