function isJSON(obj) {
  return obj && typeof obj === "object" && obj.constructor === Object;
}

function transformToArrayProfiles(data) {
  if (!Array.isArray(data)) {
    if (isJSON(data)) {
      data = [data];
    } else {
      return [];
    }
  } else {
    for (const item of data) {
      if (!isJSON(item)) {
        return [];
      }
    }
  }

  if (data.length === 0) {
    return [];
  }

  let maxKeysObject = data[0];
  for (const item of data) {
    if (Object.keys(item).length > Object.keys(maxKeysObject).length) {
      maxKeysObject = item;
    }
  }

  const headers = Object.keys(maxKeysObject);
  const result = [headers];

  data.forEach((item) => {
    const row = headers.map((header) =>
      item[header] !== undefined ? item[header] : null
    );
    result.push(row);
  });

  return result;
}

function transformToJson(data) {
  const jsonData = [];

  for (let i = 1; i < data.length; i++) {
    const jsonObject = {};
    for (let j = 0; j < data[0].length; j++) {
      const columnName = data[0][j];
      jsonObject[columnName] = data[i][j] !== undefined ? data[i][j] : null;
    }

    jsonData.push(jsonObject);
  }

  return jsonData;
}

const formatValue = (value, unit) => {
  switch (unit) {
    case "€":
      return value.toFixed(1);
    case "milliers d'€":
      return (value / 1_000).toFixed(1);
    case "millions d'€":
      return (value / 1_000_000).toFixed(1);
    case "milliards d'€":
      return (value / 1_000_000_000).toFixed(1);
    default:
      return value.toFixed(1);
  }
};

const getMaxUnit = (values) => {
  const maxAbsValue = Math.max(...values.map(Math.abs));
  const thresholds = {
    "€": 1,
    "milliers d'€": 1_000,
    "millions d'€": 1_000_000,
    "milliards d'€": 1_000_000_000,
  };

  const threshold = thresholds[getUnit(maxAbsValue)] * 0.1;

  const minAbsValue = Math.min(...values.map(Math.abs));

  if (minAbsValue < threshold) {
    return getUnit(minAbsValue);
  }
  return getUnit(maxAbsValue);
};

const getUnit = (value) => {
  if (Math.abs(value) < 1_000) return "€";
  else if (Math.abs(value) < 1_000_000) return "milliers d'€";
  else if (Math.abs(value) < 1_000_000_000) return "millions d'€";
  else return "milliards d'€";
};

function formatNumberWithComaAndSpace(number) {
  let [integerPart, decimalPart] = number.toString().split(".");

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (decimalPart) {
    decimalPart = decimalPart.replace(/(\d{3})(?=\d)/g, "$1 ");
    return `${integerPart},${decimalPart}`;
  } else {
    return integerPart;
  }
}

export {
  transformToArrayProfiles,
  transformToJson,
  formatValue,
  getUnit,
  getMaxUnit,
  formatNumberWithComaAndSpace,
};
