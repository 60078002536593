import { useMemo, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import debounce from "lodash.debounce";

const SearchGroupAutocomplete = ({
  year,
  formValid,
  value,
  onChange,
  fetchOptions,
  label = "Choisissez un groupe :",
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const debouncedFetchOptions = useMemo(
    () =>
      debounce(async (query) => {
        if (query.length > 1) {
          try {
            const response = await fetchOptions(year, query);
            const formattedOptions = response.map(
              (option) =>
                `${option.denomination_groupe} (${option.siren_groupe})`
            );
            setOptions(formattedOptions);
          } catch (error) {
            console.error("Failed to fetch options", error);
          }
        } else {
          setOptions([]);
        }
      }, 200),
    [fetchOptions, year]
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    debouncedFetchOptions(newInputValue);
  };

  const handleValueChange = (event, newValue) => {
    if (newValue) {
      const siren = newValue.substring(
        newValue.lastIndexOf("(") + 1,
        newValue.lastIndexOf(")")
      );
      onChange(siren, newValue);
    } else {
      onChange("", "");
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => option}
      value={value}
      onInputChange={handleInputChange}
      onChange={handleValueChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!formValid && !inputValue}
        />
      )}
    />
  );
};

export default SearchGroupAutocomplete;
