import IconAndValue from "../IconAndValue";
import {
  formatNumberWithComaAndSpace,
  formatValue,
} from "../../services/Finance/FinanceHelpers";
import { PiCoins } from "react-icons/pi";
import { PiChartLineUp } from "react-icons/pi";
import { FaPeopleGroup } from "react-icons/fa6";

const ImpactSchemaCard = ({
  title,
  backgroundColor,
  emploi,
  minEmploi,
  VAB,
  production,
  unit,
  forwardRef,
  height,
  width,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
}) => {
  return (
    <div
      ref={forwardRef}
      style={{
        background: backgroundColor,
        minWidth: minWidth,
        maxWidth: `${maxWidth}vw`,
        minHeight: minHeight,
        maxHeight: `${maxHeight}vh`,
        height: height,
        width: width,
        padding: "5px 15px 5px 15px",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "left",
          width: "fit-content",
        }}
      >
        <strong>{title}</strong>
        <IconAndValue
          IconName={PiCoins}
          text={`${formatNumberWithComaAndSpace(
            formatValue(production, unit)
          )} ${unit}`}
        />
        <IconAndValue
          IconName={PiChartLineUp}
          text={`${formatNumberWithComaAndSpace(
            formatValue(VAB, unit)
          )} ${unit}`}
        />
        <IconAndValue
          IconName={FaPeopleGroup}
          text={`${formatNumberWithComaAndSpace(emploi.toFixed(0))} ETP`}
        />
      </div>
    </div>
  );
};

export default ImpactSchemaCard;
