import { useState } from "react";
import SelectList from "../../components/SelectList";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import Input from "../../components/Input";
import DfGrid from "../../components/DfGrid";
import {
  translateTerritoryType,
  transformToArrayMultipliers,
} from "../../services/Tools/ToolsHelpers";
import { getMultiplier } from "../../services/Tools/ToolsService";
import { downloadXLSX } from "../../services/allHelpers";
import { ErrorAlert, SuccessAlert } from "../../components/Alert";

function MultipliersTab() {
  const [year, setYear] = useState("2019");
  const [code, setCode] = useState("");
  const [territoryType, setTerritoryType] = useState("région");
  const [formValid, setFormValid] = useState(true);

  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [dataMultiplier, setDataMultiplier] = useState([]);

  async function handleResearch() {
    if (year && code && territoryType) {
      const terrType = translateTerritoryType(territoryType);

      try {
        setSuccessAlert(false);
        setLoading(true);
        const multipliers = await getMultiplier(year, code, terrType);
        const transformedMults = transformToArrayMultipliers(multipliers);

        setDataMultiplier(transformedMults);
        setErrorAlert(false);
      } catch (error) {
        setErrorAlert(true);
        setDataMultiplier({});
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setFormValid(false);
    }
  }

  function handleDownload(dataToDownload, setterLoading, setterAlert) {
    setterLoading(true);
    setterAlert(false);
    downloadXLSX(
      dataToDownload,
      "Multiplicateurs",
      `Multiplicateurs_${year}_${territoryType}_${code}.xlsx`
    );
    setterLoading(false);
    setterAlert(true);
  }

  return (
    <>
      <h1>Multiplicateurs</h1>
      <FormGroup
        sx={{
          rowGap: "2rem",
          width: "90%",
        }}
      >
        <SelectList
          label="Choisissez une année :"
          options={["2019", "2020"]}
          value={year}
          onChange={(selectedYear) => setYear(selectedYear)}
          error={!formValid && !year}
        />
        <Input
          label="Entrez le code du territoire ciblé :"
          value={code}
          onChange={(code) => setCode(code)}
          error={!formValid && !code}
        />
        <SelectList
          label="Choisissez le type du territoire :"
          options={[
            "région",
            "département",
            "commune",
            "communauté de commune",
          ]}
          value={territoryType}
          onChange={(selectedTerritoryType) =>
            setTerritoryType(selectedTerritoryType)
          }
          error={!formValid && !territoryType}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "15%",
              minWidth: "110px",
              marginRight: "15px",
            }}
            onClick={handleResearch}
          >
            Rechercher
          </Button>
          {loading && <CircularProgress size={25} />}
          {Object.keys(dataMultiplier).length !== 0 && (
            <Button
              sx={{
                width: "15%",
                minWidth: "110px",
                marginRight: "15px",
              }}
              onClick={() => {
                handleDownload(
                  dataMultiplier,
                  setDownloadLoading,
                  setSuccessAlert
                );
              }}
              startIcon={<FileDownloadRoundedIcon />}
            >
              Télécharger
            </Button>
          )}
          {downloadLoading && <CircularProgress size={25} />}
        </div>
        {successAlert && (
          <SuccessAlert
            message="Les données ont été téléchargées avec succès."
            onClose={() => setSuccessAlert(false)}
          />
        )}
        {Object.keys(dataMultiplier).length !== 0 && (
          <DfGrid data={dataMultiplier} canEdit={false} colorRows="Code NA64" />
        )}
        {errorAlert && (
          <ErrorAlert
            message={errorMessage}
            onClose={() => setErrorAlert(false)}
          />
        )}
      </FormGroup>
    </>
  );
}

export default MultipliersTab;
