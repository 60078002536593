import { useContext } from "react";
import { createGlobalStyle } from "styled-components";
import { ThemeContext } from "../context";
import colors from "./colors";

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: 'Poppins',  sans-serif !important;
    }
    
    body {
      background-color: ${({ isDarkMode }) =>
        isDarkMode ? "#101010" : "white"};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        margin: 0;

        height:100%;
        overflow-x: hidden;

        
    }
    .ag-checkbox-input-wrapper{
      font-family: agGridQuartz !important;

    }
    span.ag-icon {
      font-family: agGridQuartz !important;
    }

    .ag-theme-quartz-dark {
      --ag-background-color: #121212;
      --ag-header-background-color: #212121;
      --ag-active-color:${colors.darkForet};
    }
    .ag-theme-quartz {
      --ag-active-color:${colors.foret};
    }
    .even-row{
      background-color: #f1f1f1;
    }
    .even-row-dark{
      background-color: #212121;
    }
    .container {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
    
    .content {
      flex-grow: 1;
    }
`;

function GlobalStyle() {
  const { theme } = useContext(ThemeContext);

  return <StyledGlobalStyle isDarkMode={theme === "dark"} />;
}

export default GlobalStyle;
