import { createContext, useContext, useEffect, useState } from "react";
import { login, logout, getAccessToken } from "../services/Auth/AuthService";
import axios from "axios";

const AuthContext = createContext();

const setAxiosAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(getAccessToken());

  const signIn = async (username, password) => {
    try {
      await login(username, password);
      const newToken = getAccessToken();
      setToken(newToken);
      setAxiosAuthToken(newToken);
    } catch (error) {
      throw new Error(error);
    }
  };

  const signOut = () => {
    logout();
    setToken(null);
    setAxiosAuthToken(null);
  };

  useEffect(() => {
    setToken(getAccessToken());
  }, []);

  useEffect(() => {
    const currentToken = getAccessToken();
    setToken(currentToken);
    setAxiosAuthToken(currentToken);
  }, []);

  const authContextValue = {
    token,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
