import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import NumberInput from "../../components/NumberInput";
import SearchGroupAutocomplete from "../../components/SearchGroupAutocomplete";
import DfGrid from "../../components/DfGrid";
import { ErrorAlert, SuccessAlert, WarningAlert } from "../../components/Alert";
import { downloadXLSX } from "../../services/allHelpers";
import {
  consolidateData,
  editProfilage,
  getProfilagePreconsoForYear,
  getAllConsolidatedProfiles,
  getAllSirenGroupForYear,
} from "../../services/Finance/FinanceService";
import {
  transformToArrayProfiles,
  transformToJson,
} from "../../services/Finance/FinanceHelpers";

function ProfileEditingTab() {
  const maxYear = parseInt(new Date().getFullYear() - 1);

  const [year, setYear] = useState(maxYear);
  const [sirenGroup, setSirenGroup] = useState("");
  const [formValid, setFormValid] = useState(true);
  const [displayedSirenGroup, setDisplayedSirenGroup] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingConsolidate, setLoadingConsolidate] = useState(false);
  const [loadingDownloadPreconso, setLoadingDownloadPreconso] = useState(false);
  const [loadingDownloadConso, setLoadingDownloadConso] = useState(false);

  const [successAlert, setSuccessAlert] = useState(false);
  const [successAlertConsolidation, setSuccessAlertConsolidation] =
    useState(false);
  const [successAlertDownloadPreconso, setSuccessAlertDownloadPreconso] =
    useState(false);
  const [successAlertDownloadConso, setSuccessAlertDownloadConso] =
    useState(false);

  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningAlert, setWarningAlert] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const [profilesData, setProfilesData] = useState({});
  const [consolidatedProfilesData, setConsolidatedProfilesData] = useState({});
  const [updatedProfilesData, setUpdatedProfilesData] = useState([]);
  const [header, setHeader] = useState([]);

  const [sumTableData, setSumTableData] = useState([]);

  async function fetchSirenOptions(year, query) {
    return await getAllSirenGroupForYear(year, query, "preconso");
  }

  function handleSirenGroupChange(siren, displayedValue) {
    setSirenGroup(siren);
    setDisplayedSirenGroup(displayedValue);
  }

  async function consolidateAndDisplayData() {
    try {
      const consolidatedProfiles = await getAllConsolidatedProfiles(
        year,
        sirenGroup
      );
      const transformedConsolidatedProfiles =
        transformToArrayProfiles(consolidatedProfiles);
      if (sirenGroup) {
        const profilesSumHeaders = [
          "denomination_groupe",
          "siren_groupe",
          "achats_de_marchandises_y_compris_droits_de_douane",
          "achats_BS",
          "ventes_de_marchandises",
          "ventes_BS",
        ];

        let profilesSumContent = [];

        profilesSumHeaders.forEach((col, index) => {
          if (index === 0) {
            profilesSumContent.push(transformedConsolidatedProfiles[1][0]);
          } else if (index === 1) {
            profilesSumContent.push(transformedConsolidatedProfiles[1][1]);
          } else {
            let sum = 0;
            consolidatedProfiles.forEach((profile) => {
              sum += profile[col] || 0;
            });
            profilesSumContent.push(sum);
          }
        });

        let sumTable = [];
        sumTable.push(profilesSumHeaders);
        sumTable.push(profilesSumContent);

        setSumTableData(sumTable);
      }

      setConsolidatedProfilesData(transformedConsolidatedProfiles);
    } catch (error) {
      if (
        error.message === "Il n'y a pas de profils consolidés pour cette année."
      ) {
        setWarningAlert(true);
        setWarningMessage(error.message);
      } else {
        setErrorAlert(true);
        setErrorMessage(error.message);
      }
      setSuccessAlert(false);
    }
  }

  async function handleResearch() {
    if (year) {
      try {
        setProfilesData({});
        setConsolidatedProfilesData({});
        setSumTableData({});
        setErrorAlert(false);
        setSuccessAlert(false);
        setSuccessAlertConsolidation(false);
        setSuccessAlertDownloadPreconso(false);
        setSuccessAlertDownloadConso(false);
        setWarningAlert(false);

        setLoading(true);
        const profiles = await getProfilagePreconsoForYear(year, sirenGroup);
        const transformedProfiles = transformToArrayProfiles(profiles);
        setHeader(transformedProfiles[0]);
        setProfilesData(transformedProfiles);

        consolidateAndDisplayData();
      } catch (error) {
        setErrorAlert(true);
        setErrorMessage(error.message);
        setSuccessAlert(false);
      } finally {
        setLoading(false);
      }
    } else {
      setFormValid(false);
    }
  }

  const handleProcessRowUpdate = (newData) => {
    updatedProfilesData.push(newData.slice(1));
    setSuccessAlert(false);
  };

  function handleDownload(
    dataToDownload,
    setterLoading,
    setterAlert,
    fileName
  ) {
    setterLoading(true);
    setterAlert(false);
    downloadXLSX(dataToDownload, "Profils", fileName);
    setterLoading(false);
    setterAlert(true);
  }

  async function handleUpdateData() {
    if (updatedProfilesData.length > 0) {
      try {
        setLoadingUpdate(true);
        setSuccessAlertConsolidation(false);
        updatedProfilesData.unshift(header);
        const jsonData = transformToJson(updatedProfilesData);
        await editProfilage(jsonData);
        setErrorAlert(false);
        setSuccessAlert(true);
        setUpdatedProfilesData([]);
      } catch (error) {
        setSuccessAlert(false);
        setErrorAlert(true);
        setErrorMessage(error.message);
      } finally {
        setLoadingUpdate(false);
      }
    } else {
      setSuccessAlert(false);
      setErrorAlert(true);
      setErrorMessage("Vous n'avez modifié aucune valeur dans le tableau.");
    }
  }

  async function handleConsolidateData() {
    if (profilesData) {
      try {
        setLoadingConsolidate(true);
        setSuccessAlert(false);
        setConsolidatedProfilesData({});
        const profilesEdited = await getProfilagePreconsoForYear(
          year,
          sirenGroup
        );
        await consolidateData(profilesEdited);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        consolidateAndDisplayData();
        setErrorAlert(false);
        setSuccessAlertConsolidation(true);
      } catch (error) {
        setSuccessAlertConsolidation(false);
        setErrorAlert(true);
        setErrorMessage(error.message);
      } finally {
        setLoadingConsolidate(false);
      }
    }
  }

  return (
    <>
      <h1>Étape 3 : Consolidation</h1>
      <FormGroup
        sx={{
          rowGap: "2rem",
        }}
      >
        <NumberInput
          label="Année d'étude"
          value={year}
          onChange={(year) => setYear(year)}
          minValue={2015}
          maxValue={maxYear}
          error={!formValid && !year}
        />
        <SearchGroupAutocomplete
          year={year}
          value={displayedSirenGroup}
          onChange={handleSirenGroupChange}
          fetchOptions={fetchSirenOptions}
          label="Choisissez un groupe (facultatif) :"
          formValid={formValid}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "15%",
              minWidth: "110px",
              marginRight: "15px",
            }}
            onClick={handleResearch}
          >
            Rechercher
          </Button>
          {loading && <CircularProgress size={25} />}
        </div>

        <div
          style={{
            width: "100%",
          }}
        >
          {Object.keys(profilesData).length !== 0 && (
            <>
              <div style={{ marginBottom: "1rem" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h2>Édition des profils</h2>
                  <Button
                    startIcon={<FileDownloadRoundedIcon />}
                    sx={{
                      minWidth: "110px",
                      marginRight: "15px",
                      marginLeft: "15px",
                    }}
                    onClick={() => {
                      handleDownload(
                        profilesData,
                        setLoadingDownloadPreconso,
                        setSuccessAlertDownloadPreconso,
                        `Profils_à_consolider_${year}.xlsx`
                      );
                    }}
                  >
                    Télécharger
                  </Button>

                  {loadingDownloadPreconso && <CircularProgress size={25} />}
                </div>
                {successAlertDownloadPreconso && (
                  <SuccessAlert
                    message="Les données ont été téléchargées avec succès."
                    onClose={() => setSuccessAlertDownloadPreconso(false)}
                  />
                )}
              </div>

              <DfGrid
                data={profilesData}
                canEdit={true}
                editableCols={["isClassif", "code_classification"]}
                colorRows="siren_group"
                onDataChange={handleProcessRowUpdate}
              />
            </>
          )}
          {Object.keys(profilesData).length !== 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: "15%",
                  minWidth: "144px",
                  marginRight: "1rem",
                }}
                onClick={handleUpdateData}
              >
                Mettre à jour
              </Button>
              {loadingUpdate && <CircularProgress size={25} />}
            </div>
          )}
          {Object.keys(profilesData).length !== 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "15%",
                  minWidth: "110px",
                  marginRight: "1rem",
                }}
                onClick={handleConsolidateData}
              >
                Consolider
              </Button>
              {loadingConsolidate && <CircularProgress size={25} />}
            </div>
          )}
          <div style={{ marginBottom: "2rem" }}>
            {errorAlert && (
              <ErrorAlert
                message={errorMessage}
                onClose={() => setErrorAlert(false)}
              />
            )}

            {successAlert && (
              <SuccessAlert
                message="Les données ont été mises à jour avec succès."
                onClose={() => setSuccessAlert(false)}
              />
            )}
            {successAlertConsolidation && (
              <SuccessAlert
                message="Les données ont été consolidées avec succès."
                onClose={() => setSuccessAlertConsolidation(false)}
              />
            )}
          </div>
          {Object.keys(consolidatedProfilesData).length !== 0 && (
            <div>
              {Object.keys(sumTableData).length === 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <h2>Profils consolidés</h2>
                    <Button
                      startIcon={<FileDownloadRoundedIcon />}
                      sx={{
                        minWidth: "110px",
                        marginRight: "15px",
                        marginLeft: "15px",
                      }}
                      onClick={() => {
                        handleDownload(
                          consolidatedProfilesData,
                          setLoadingDownloadConso,
                          setSuccessAlertDownloadConso,
                          `Profils_consolidés_${year}.xlsx`
                        );
                      }}
                    >
                      Télécharger
                    </Button>

                    {loadingDownloadConso && <CircularProgress size={25} />}
                  </div>
                  {successAlertDownloadConso && (
                    <div style={{ marginBottom: "1rem" }}>
                      <SuccessAlert
                        message="Les données ont été téléchargées avec succès."
                        onClose={() => setSuccessAlertDownloadConso(false)}
                      />
                    </div>
                  )}
                </>
              )}
              {Object.keys(sumTableData).length !== 0 && (
                <>
                  <h2>Profils consolidés</h2>
                  <h3>Total</h3>
                  <DfGrid
                    data={sumTableData}
                    canFilter={false}
                    displayPagination={false}
                    rowSize={40}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <h3>Détail des filiales</h3>
                    <Button
                      startIcon={<FileDownloadRoundedIcon />}
                      sx={{
                        minWidth: "110px",
                        marginRight: "15px",
                        marginLeft: "15px",
                      }}
                      onClick={() => {
                        handleDownload(
                          consolidatedProfilesData,
                          setLoadingDownloadConso,
                          setSuccessAlertDownloadConso,
                          `Profils_consolidés_${year}.xlsx`
                        );
                      }}
                    >
                      Télécharger
                    </Button>

                    {loadingDownloadConso && <CircularProgress size={25} />}
                  </div>
                  {successAlertDownloadConso && (
                    <div style={{ marginBottom: "1rem" }}>
                      <SuccessAlert
                        message="Les données ont été téléchargées avec succès."
                        onClose={() => setSuccessAlertDownloadConso(false)}
                      />
                    </div>
                  )}
                </>
              )}

              <DfGrid data={consolidatedProfilesData} canEdit={false} />
            </div>
          )}

          {warningAlert && (
            <WarningAlert
              message={warningMessage}
              onClose={() => setWarningAlert(false)}
            />
          )}
        </div>
      </FormGroup>
    </>
  );
}

export default ProfileEditingTab;
